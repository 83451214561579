<template>
  <div class="SectionNumbers">
    <SectionSplit class="reverse" src="../../assets/landing-landowners/plants.jpg">
      <div v-if="locale === 'lt'">
        <NumberItem description="Mūsų ekspertai" :value="getSnippetValue('experts')" />
        <NumberItem description="Įrengta fotovoltinė galia" :value="`${getSnippetValue('constructedMw')} MW`" />
        <NumberItem description="Šiuo metu vystomų projektų galia" :value="`${getSnippetValue('developingGw')} GW`" />
        <NumberItem description="Biurai Lenkijoje, Lietuvoje, Italijoje ir Vokietijoje" value="6" />
      </div>
      <div v-else>
        <NumberItem description="łącznej mocy instalacji w trakcie procedowania" :value="`${getSnippetValue('developingGw')} GW`" />
        <NumberItem description="zrealizowanych projektów" value="109" />
        <!-- <NumberItem value="23" description="projekty w trakcie realizacji" /> -->
        <NumberItem description="oddziały w Polsce" value="2" />
        <NumberItem description="projektów zrealizowanych zgodnie z wymaganiami Urzędu Regulacji Energetyki" value="100%" />
      </div>
    </SectionSplit>
  </div>
</template>

<script>
import NumberItem from './NumberItem'
import SectionSplit from 'views/components/SectionSplit'
import { getters } from 'views/utils'

export default {
  components: { SectionSplit, NumberItem },
  computed: getters('getSnippetValue'),
}
</script>

<style lang="scss">
.SectionNumbers {
  background-color: $grey-lightest;

  .SectionSplit {
    .image {
      min-height: 100%;
    }

    .Splitter-body {
      max-width: 100%;
    }
  }
}
</style>
