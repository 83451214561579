<template>
  <div class="SectionAboutSig">
    <Wrap class="xl">
      <div class="body">
        <h3>
          {{ $t('title') }}
        </h3>
        <div class="text">
          <p v-for="paragraph in $ta('paragraphs')" :key="paragraph" v-html="injectSnippets(paragraph)" />
        </div>
        <Btn :to="{ name: 'Index' }">
          {{ $t('cta') }}
        </Btn>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  computed: getters('injectSnippets'),
}
</script>

<style lang="scss">
.SectionAboutSig {
  background-color: $grey-lighter;
  padding: 2rem 0;

  .body {
    padding: 3rem 0;

    .text {
      margin: 1rem 0;

      p {
        margin-bottom: 1rem;
      }
    }

    .Btn {
      margin-top: 1rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "cta": "Mehr Informationen",
    "paragraphs": [
      "",
      ""
    ],
    "title": ""
  },
  "en": {
    "cta": "More information",
    "paragraphs": [
      "Sun Investment Group is a solar development and investment management company, focused on delivering steady quality in the rapidly evolving solar energy industry. The company has extensive experience in developing solar power projects across Poland and the Baltics. Together with its group of companies, Sun Investment Group is considered to be the leader of the solar sector in the region.",
      "The company was founded in 2017 and is currently operating from 5 offices in Poland, Lithuania, and Italy. Sun Investment Group has a track record of delivering a total of {constructedMw} MW capacity and {developingMw}+ MW of projects in the development phase. Its team consists of almost 100 solar energy professionals."
    ],
    "title": "About Sun Investment Group"
  },
  "it": {
    "cta": "Maggiori Informazioni",
    "paragraphs": [
      "Sun Investment Group è una <b>società di investimento</b> che si occupa di sviluppo e realizzazione di impianti fotovoltaici. L'azienda ha una grande esperienza nello sviluppare progetti in Polonia e nelle Repubbliche Baltiche, dove il Gruppo è considerato un <b>leader nel settore fotovoltaico.</b> L'azienda è stata fondata nel 2017 e attualmente opera attraverso <b>5 sedi tra Polonia, Lituania e Italia.</b> Sun Investment Group ha un track record di oltre 164 MWp di impianti realizzati e oltre 1.4 GWp di progetti in fase di sviluppo autorizzativo. Il team consiste in oltre 190 professionisti dedicati interamente al settore fotovoltaico. I progetti sviluppati da Sun Investment Group sono presenti nell'elenco dei <b>40 più grandi portafogli di impianti fotovoltaici in Europa</b> (Solarplaza, 2021).",
      ""
    ],
    "title": "Sun Investment Group - Chi siamo"
  },
  "lt": {
    "cta": "Daugiau informacijos",
    "paragraphs": [
      "„Sun Investment Group“ (SIG) yra atsinaujinančios energetikos projektų vystymo ir investicijų valdymo įmonė, užtikrinanti stabilią kokybę greitai kintančiame ir augančiame sektoriuje. SIG įmonių grupė sudaro vieną dinamiškiausių ir sparčiausiai augančių saulės energetikos technologijų grupių Europoje.",
      "Įkurta 2017 metais, šiuo metu SIG jungia daugiau nei {experts} patyrusių saulės energetikos projektų valdymo, struktūrinio finansavimo, teisės, statybos ir turto valdymo ekspertų žinias. SIG komanda jau įgyvendino {constructedMw} MW galios saulės energetikos projektus."
    ],
    "title": "Apie „Sun Investment Group“"
  },
  "pl": {
    "cta": "Więcej informacji",
    "paragraphs": [
      "Spółka Sun Investment Group (SIG) została założona na Litwie. W Polsce działa od 2017 roku realizując Politykę Energetyczną, poprzez rozwój projektów fotowoltaicznych. Głównym obszarem działania firmy jest budowa farm fotowoltaicznych na wydzierżawionych gruntach. Oprócz Polski i Litwy SIG jest obecny również we Włoszech i Hiszpanii.",
      "Do tej pory w Polsce zrealizowaliśmy 109 farm fotowoltaicznych na terenie całego kraju. Dzięki zaufaniu jakim nas obdarzono możemy rozwijać kolejne elektrownie. Oprócz finansowania i budowy gotowych już projektów, poszukujemy również terenów, na których moglibyśmy realizować nowe inwestycje."
    ],
    "title": "Sun Investment Group – lider branży budowy farm fotowoltaicznych"
  }
}
</i18n>
