<template>
  <div id="form" class="FormContactLandowners">
    <Wrap class="lg">
      <h3>
        {{ $t('title') }}
      </h3>
      <FormValidation v-if="!hasSent" @submit="send">
        <ValidationObserver ref="formObs" tag="div">
          <div v-if="locale === 'pl'" class="polish-form">
            <Row>
              <Column :lg="12">
                <Field
                  autocomplete="name"
                  :label="$t('fullName')"
                  :maxLength="100"
                  required
                  :value.sync="contact.name" />
              </Column>
              <!-- <Column :lg="6">
                <Field
                  autocomplete="lname"
                  :label="$t('lastName')"
                  :maxLength="50"
                  required
                  :value.sync="lastName" />
              </Column> -->
            </Row>
            <Row>
              <Column :lg="6">
                <Field
                  autocomplete="tel"
                  :label="$t('phone')"
                  :maxLength="50"
                  required
                  rules="phone"
                  :value.sync="contact.phone" />
              </Column>
              <Column :lg="6">
                <Field
                  autocomplete="email"
                  :label="$t('email')"
                  :maxLength="130"
                  rules="email"
                  type="email"
                  :value.sync="contact.email" />
              </Column>
            </Row>
            <Row>
              <Column :lg="6">
                <Field
                  :label="$t('area')"
                  :max="9999"
                  :min="0.1"
                  required
                  :step="0.001"
                  type="number"
                  :value.sync="contact.area" />
                <!-- <Field
                  :label="$t('area')"
                  :options="getAreaSelectOptionsWithLabels(areaSelectOptionsPl)"
                  :placeholderSelect="$t('selectOne')"
                  type="select"
                  :value.sync="contact.area" /> -->
                <!-- <Field
                  autocomplete=""
                  :label="$t('area')"
                  :maxLength="100"
                  required
                  :value.sync="contact.area" /> -->
              </Column>
              <Column :lg="6">
                <Field
                  :label="$t('plot')"
                  :maxLength="50"
                  required
                  :value.sync="contact.plot" />
              </Column>
            </Row>
            <Row>
              <Column :lg="6">
                <Field
                  autocomplete=""
                  :label="$t('city')"
                  :maxLength="100"
                  required
                  :value.sync="contact.city" />
              </Column>
              <Column :lg="6">
                <Field
                  :label="$t('municipality')"
                  :maxLength="100"
                  :value.sync="contact.commune" />
              </Column>
            </Row>
            <Row>
              <Column :lg="6">
                <Field
                  :label="$t('county')"
                  :maxLength="100"
                  required
                  :value.sync="contact.county" />
              </Column>
              <Column :lg="6">
                <!-- <Field
                  required
                  :label="$t('voivodeship')"
                  :maxLength="50"
                  :value.sync="contact.voivodeship" /> -->
                <Field
                  :label="$t('voivodeship')"
                  :options="voivodeshipOptions"
                  :placeholderSelect="$t('selectOne')"
                  required
                  type="select"
                  :value.sync="contact.voivodeship" />
              </Column>
            </Row>
            <!-- <Field
              :label="$t('howFound')"
              type="select"
              :placeholderSelect="$t('selectOne')"
              :options="howFoundOptions"
              :value.sync="contact.howFound" />
            <Field
              type="textarea"
              :label="$t('additionalQuestion')"
              :maxLength="1000"
              :value.sync="contact.message" /> -->
          </div>
          <div v-if="locale === 'lt'" class="polish-form">
            <Row>
              <Column :lg="12">
                <Field
                  autocomplete="name"
                  :label="$t('fullName')"
                  :maxLength="100"
                  required
                  :value.sync="contact.name" />
              </Column>
            </Row>
            <Row>
              <Column :lg="6">
                <Field
                  autocomplete="tel"
                  :label="$t('phone')"
                  :maxLength="50"
                  required
                  rules="phone"
                  :value.sync="contact.phone" />
              </Column>
              <Column :lg="6">
                <Field
                  autocomplete="email"
                  :label="$t('email')"
                  :maxLength="130"
                  required
                  rules="email"
                  type="email"
                  :value.sync="contact.email" />
              </Column>
            </Row>
            <Row>
              <Column :lg="6">
                <Field
                  class="hasFooter"
                  :label="$t('plot')"
                  :maxLength="50"
                  required
                  :value.sync="contact.plot">
                  <template #footer>
                    <div class="Field-footer">
                      {{ $t('plotFieldFooter') }}
                    </div>
                  </template>
                </Field>
              </Column>
              <Column :lg="6">
                <Field
                  :label="$t('area')"
                  :options="getAreaSelectOptionsWithLabels(areaSelectOptionsLt)"
                  :placeholderSelect="$t('selectOne')"
                  required
                  type="select"
                  :value.sync="contact.area" />
              </Column>
            </Row>
            <Field
              :label="$t('additionalQuestion')"
              :maxLength="1000"
              type="textarea"
              :value.sync="contact.message" />
          </div>
          <div v-if="locale === 'it' || locale === 'en' || locale === 'es'" class="common-form">
            <Row>
              <Column :lg="6">
                <Field
                  autocomplete="fname"
                  :label="$t('firstName')"
                  :maxLength="50"
                  required
                  :value.sync="firstName" />
              </Column>
              <Column :lg="6">
                <Field
                  autocomplete="lname"
                  :label="$t('lastName')"
                  :maxLength="50"
                  required
                  :value.sync="lastName" />
              </Column>
            </Row>
            <Row>
              <Column :lg="6">
                <div class="phoneFields">
                  <Field
                    autocomplete="tel-country-code"
                    class="countryCode"
                    disabled
                    :value="phoneNumberCodeItalianEnglishSpanish" />
                  <Field
                    autocomplete="tel"
                    class="phoneNumber"
                    :label="$t('phone')"
                    :maxLength="10"
                    required
                    rules="phone"
                    :value.sync="contact.phone" />
                </div>
              </Column>
              <Column :lg="6">
                <Field
                  autocomplete="email"
                  :label="$t('email')"
                  :maxLength="130"
                  required
                  rules="email"
                  type="email"
                  :value.sync="contact.email" />
              </Column>
            </Row>
            <Row>
              <template v-if="locale !== 'es'">
                <Column :lg="6">
                  <FieldAutosuggest
                    :label="$t('city')"
                    :optionsAll="italianCities"
                    required
                    :value.sync="contact.city" />
                </Column>
                <Column :lg="6">
                  <FieldAutosuggest
                    :disabled="!italianComunes.length"
                    :label="$t('commune')"
                    :optionsAll="italianComunes"
                    required
                    :value.sync="contact.commune" />
                </Column>
              </template>
              <template v-else>
                <Column :lg="6">
                  <Field
                    :label="$t('city')"
                    required
                    :value.sync="contact.city" />
                </Column>
                <Column :lg="6">
                  <Field
                    :label="$t('commune')"
                    required
                    :value.sync="contact.commune" />
                </Column>
              </template>
            </Row>
            <Row>
              <Column :lg="6">
                <Field
                  :label="$t('type')"
                  :options="$ta('typeOptions')"
                  :placeholderSelect="$t('typePlaceholder')"
                  :required="isLandTypeRequiredItalianEnglishSpanish"
                  type="select"
                  :value.sync="contact.landType" />
              </Column>
              <Column :lg="6">
                <Field
                  :label="$t('area')"
                  :max="999"
                  :maxLength="3"
                  :min="0.1"
                  oninput="value = value.slice(0,3)"
                  required
                  :step="0.1"
                  type="number"
                  :value.sync="contact.area">
                  <template #footer>
                    <div class="Field-footer">
                      {{ $t('fieldFooter') }}
                    </div>
                  </template>
                </Field>
              </Column>
            </Row>
          </div>
          <div class="checkboxes">
            <NoticeRecaptcha />
            <FieldConsent :value.sync="contact.hasAcceptedPolicy" />
            <FieldConsentMarketing :value.sync="contact.hasAcceptedMarketing" />

            <Btn class="submit" :isLoading="isSending" type="submit">
              {{ $t('send') }}
            </Btn>
          </div>
        </ValidationObserver>
      </FormValidation>
      <Notice v-if="hasSent && message" :class="message.theme">
        {{ message.text }}
      </Notice>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import ComunesByCities from './ItalianEnglishSpanish/comunesByCities.json'
import FieldAutosuggest from './ItalianEnglishSpanish/FieldAutosuggest'
import Message from 'views/mixins/Message'
import NoticeRecaptcha from 'views/components/NoticeRecaptcha'
import { isMock } from '../../../../config'
import { softThrow } from '../../../../bugsnag'

export default {
  mixins: [Message],
  components: { NoticeRecaptcha, FieldAutosuggest },
  data() {
    return {
      comunesByCities: ComunesByCities,
      contact: this.$store.getters.contactFactory({
        type: 'landowner',
        formType: 'landowner-landing',
      }),
      isSending: false,
      hasSent: false,
      firstName: '',
      lastName: '',
      areaSelectOptions: [
        '<12',
        '15',
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        '>50',
      ],
      areaSelectOptionsPl: [
        '<10',
        '10',
        '12',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '>20',
      ],
      areaSelectOptionsLt: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '>10',
      ],
      howFoundOptions: [
        'Facebook',
        'Google',
        'Ulotki',
        'Plakaty',
        'Inne',
      ],
      voivodeshipOptions: [
        'Dolnośląskie',
        'Kujawsko-pomorskie',
        'Lubelskie',
        'Lubuskie',
        'Łódzkie',
        'Małopolskie',
        'Mazowieckie',
        'Opolskie',
        'Podkarpackie',
        'Podlaskie',
        'Pomorskie',
        'Śląskie',
        'Świętokrzyskie',
        'Warmińsko-mazurskie',
        'Wielkopolskie',
        'Zachodniopomorskie',
      ],
    }
  },
  computed: {
    italianCities() {
      return Object.keys(this.comunesByCities)
    },
    italianComunes() {
      if (!this.contact.city) return []
      return this.comunesByCities[this.contact.city]
    },
    italianCitiesDisabled() {
      return !this.italianCities
    },
    fullName() {
      return `${this.firstName} ${this.lastName}`
    },
    gtagEventName() {
      return this.locale === 'pl' ? 'FormSubmissionLarge' : 'FormSubmissionSmall'
    },
    phoneNumberCodeItalianEnglishSpanish() {
      return this.locale === 'es' ? '+34' : '+39'
    },
    phoneFormated() {
      if (!this.contact.phone) return ''

      if (this.locale === 'it' || this.locale === 'en') return `+39${this.contact.phone}`

      if (this.locale === 'es') return `+34${this.contact.phone}`

      return this.contact.phone
    },
    isLandTypeRequiredItalianEnglishSpanish() {
      return this.locale !== 'es'
    },
    ...getters('referrers'),
  },
  methods: {
    getAreaSelectOptionsWithLabels(areaSelectOptions) {
      return areaSelectOptions
        .map(option => [option, `${option} ${this.$t('hectares')}`])
    },
    async send() {
      this.isSending = true
      this.contact.name = this.contact.name || this.fullName // Form has separate name and surname fields or one for both
      this.contact.area = this.contact.area ? this.contact.area.toString() : null

      // const phoneFormated = ((this.locale === 'it' || this.locale === 'en') && this.contact.phone)
      //   ? `+39${this.contact.phone}`
      //   : this.contact.phone

      try {
        if (!isMock) {
          const recaptcha = await this.$recaptchaToken('contact')
          await this.CREATE_CONTACT({
            ...this.contact,
            phone: this.phoneFormated,
            metaReferrers: this.referrers,
            recaptcha,
          })
        }

        this.hasSent = true

        this.$gtag.event(this.gtagEventName)
        this.$analytics.fbq.event('Lead')
        this.success(this.$t('successLandowner'))
      } catch (error) {
        error.body = this.contact
        softThrow(error)
        // this.ERROR(error)
      } finally {
        this.isSending = false
      }
    },
    ...actions('CREATE_CONTACT'),
  },
}
</script>

<style lang="scss">
.FormContactLandowners {
  background-color: $main;
  color: $white;
  padding: 4rem 0;

  a {
    border-bottom: 1px solid $grey-lightest;
    color: $white;

    &:hover {
      border-color: $white;
    }
  }

  span {
    color: $white;
  }

  h3 {
    margin-bottom: 4rem;
  }

  textarea {
    min-height: 80px;
  }

  .checkboxes {
    margin-top: 0.5rem;
    text-align: left;
    @include md {
      margin-top: 0;
    }
  }

  form {
    @include lg {
      margin-top: 3rem;
    }

    .phoneFields {
      display: flex;
      margin-bottom: -2rem;
      @include md {
        margin-bottom: -3rem;
      }

      > .countryCode {
        min-width: 4rem;
        width: 4rem;

        input {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          text-align: right;
        }
      }

      > .phoneNumber {
        flex-grow: 1;
        // width: 90%;

        label {
          left: -4rem;
          position: absolute;
        }

        input {
          border-bottom-left-radius: 0;
          border-left: 1px solid;
          border-top-left-radius: 0;
        }
      }
    }

    .Field-cont {
      &.hasFooter {
        margin-bottom: 1.5rem;
      }
    }

    .Field {
      margin-bottom: 3rem;

      .Field-footer {
        border: none;
        font-size: 0.9rem;
        text-align: right;
        width: 100%;
      }

      .Icon {
        color: $main;
      }

      &.type-select {
        border: none;
      }
    }

    label {
      top: -2rem;
    }

    input,
    select,
    textarea {
      background-color: $white;
      border: 1px solid;
      text-align: left;
      text-align-last: left;
    }

    .checkboxes {
      text-align: center;

      .Field-cont {
        margin-bottom: 0;
      }

      .Field {
        margin-bottom: 1rem;
      }

      .submit-column {
        text-align: center;
        @include md {
          text-align: right;
        }
      }
    }

    .submit {
      background: $white;
      color: $main;
      max-width: 20rem;
      min-width: 9rem;
      width: 100%;

      &:hover {
        background: $grey-lighter;
      }
    }
  }

  .Notice {
    margin: 2rem 0;
  }
}
</style>

<i18n>
{
  "de": {
    "additionalQuestion": "",
    "area": "",
    "areaHelp": "",
    "city": "",
    "commune": "",
    "county": "",
    "email": "",
    "fieldFooter": "",
    "firstName": "",
    "fullName": "",
    "hectares": "",
    "howFound": "",
    "lastName": "",
    "municipality": "",
    "phone": "",
    "plot": "",
    "plotFieldFooter": "",
    "plotHelp": "",
    "selectOne": "",
    "send": "",
    "successLandowner": "",
    "title": "",
    "type": "",
    "typeOptions": [
      "",
      "",
      "",
      ""
    ],
    "typePlaceholder": "",
    "voivodeship": ""
  },
  "en": {
    "additionalQuestion": "",
    "area": "Area (Ha)",
    "areaHelp": "",
    "city": "City",
    "commune": "Comune",
    "county": "",
    "email": "Email",
    "fieldFooter": "10 ha = 100.000 mq",
    "firstName": "First name",
    "fullName": "",
    "hectares": "hectares",
    "howFound": "",
    "lastName": "Last name",
    "municipality": "Municipality",
    "phone": "Phone",
    "plot": "",
    "plotFieldFooter": "",
    "plotHelp": "",
    "selectOne": "",
    "send": "Send",
    "successLandowner": "Thank you. We will contact you shortly.",
    "title": "Get your land evaluated! Fill in the form",
    "type": "Type",
    "typeOptions": [
      "Agricultural",
      "Industrial",
      "Other",
      ""
    ],
    "typePlaceholder": "Choose one",
    "voivodeship": ""
  },
  "it": {
    "additionalQuestion": "",
    "area": "Dimensione in ettari",
    "areaHelp": "",
    "city": "Provincia",
    "commune": "Comune",
    "county": "",
    "email": "Email",
    "fieldFooter": "1 ettaro = 10.000mq",
    "firstName": "Nome",
    "fullName": "",
    "hectares": "ettari",
    "howFound": "",
    "lastName": "Cognome",
    "municipality": "Comune e Provincia",
    "phone": "Numero di Telefono",
    "plot": "",
    "plotFieldFooter": "",
    "plotHelp": "",
    "selectOne": "",
    "send": "Invia",
    "successLandowner": "Grazie mille! Il nostro team vi contatterà all' indirizzo mail indicato.",
    "title": "Richiedi la tua valutazione via mail compilando il form",
    "type": "Tipologia d'uso",
    "typeOptions": [
      "Agricolo",
      "Industriale",
      "Cava",
      "Altro"
    ],
    "typePlaceholder": "Scegline uno",
    "voivodeship": ""
  },
  "lt": {
    "additionalQuestion": "Klausimai bei komentarai",
    "area": "Žemės sklypo dydis",
    "areaHelp": "",
    "city": "",
    "commune": "",
    "county": "",
    "email": "Elektroninis paštas",
    "fieldFooter": "",
    "firstName": "",
    "fullName": "Vardas, Pavardė",
    "hectares": "ha",
    "howFound": "",
    "lastName": "",
    "municipality": "",
    "phone": "Telefono numeris",
    "plot": "Unikalus žemės sklypo numeris",
    "plotFieldFooter": "(12 skaitmenų numerį rasite žemės nuosavybės dokumentuose)",
    "plotHelp": "",
    "selectOne": "Pasirinkite",
    "send": "Siųsti",
    "successLandowner": "Dėkojame! Netrukus su jumis susisieksime.",
    "title": "Užpildykite užklausos formą ir mūsų ekspertai su jumis susisieks",
    "type": "",
    "typeOptions": [
      "",
      "",
      "",
      ""
    ],
    "typePlaceholder": "",
    "voivodeship": ""
  },
  "pl": {
    "additionalQuestion": "Dodatkowe pytania",
    "area": "Powierzchnia (ha.)",
    "areaHelp": "Potrzebujemy gruntów o minimalnej powierzchni 1,6 ha, klasy IV-VI lub nieużytki, umożliwi nam to zbudowanie instalacji o mocy 1 MGW",
    "city": "Miejscowość/obręb",
    "commune": "Gmina",
    "county": "Powiat",
    "email": "Email",
    "fieldFooter": "",
    "firstName": "",
    "fullName": "Imię i nazwisko",
    "hectares": "hektarów",
    "howFound": "Skąd dowiedział się Pan/Pani o naszej ofercie?",
    "lastName": "",
    "municipality": "Gmina",
    "phone": "Numer telefonu",
    "plot": "Numer działki",
    "plotFieldFooter": "",
    "plotHelp": "Numer działki jest nam potrzebny, by sprawdzić, czy działka spełnia nasze wymagania",
    "selectOne": "Wybierz jeden",
    "send": "Wyślij",
    "successLandowner": "Dziękujemy, skontaktujemy się z Tobą w ciągu 10 dni roboczych.",
    "title": "Skontaktuj się z nami, wypełnij formularz, a my sprawdzimy Twoją ziemię!",
    "type": "",
    "typeOptions": [
      "",
      "",
      "",
      ""
    ],
    "typePlaceholder": "",
    "voivodeship": "Województwo"
  },
  "es": {
    "additionalQuestion": "",
    "area": "Tamaño en Hectáreas",
    "areaHelp": "",
    "city": "Provincia",
    "commune": "Municipio",
    "county": "",
    "email": "Correo electrónico",
    "fieldFooter": "1 hectárea = 10.000 m²",
    "firstName": "Nombre",
    "fullName": "",
    "hectares": "ettari",
    "howFound": "",
    "lastName": "Apellidos",
    "municipality": "Comune e Provincia",
    "phone": "Número de Teléfono",
    "plot": "",
    "plotFieldFooter": "",
    "plotHelp": "",
    "selectOne": "",
    "send": "Enviar",
    "successLandowner": "¡Mil gracias! Nuestro equipo se pondrá en contacto con usted en la dirección de correo electrónico indicada.",
    "title": "Solicite su análisis por correo electrónico rellenando el formulario",
    "type": "Tipo de uso",
    "typeOptions": [
      "Agrícola",
      "Industrial",
      "Cantera",
      "Vertedero",
      "Otro"
    ],
    "typePlaceholder": "Selecciona uno",
    "voivodeship": ""
  }
}
</i18n>
