<template>
  <div class="PlotViewer">
    <Modal v-if="isLoaded && plots.length < 1">
      <Notice class="warning">
        {{ $t('notFound') }}
      </Notice>
    </Modal>
    <GmapMap
      ref="map"
      id="google-map"
      :center="center"
      mapTypeId="hybrid"
      :options="{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
      }"
      style="height: 100%;
width: 100%;"
      :zoom="7" />
  </div>
</template>
<script>
import * as VueGoogleMaps from 'vue2-google-maps'
import { actions, getters } from '../../utils'
import Vue from 'vue'
import { googleMapsKey } from '../../../config'

Vue.use(VueGoogleMaps, {
  load: {
    key: googleMapsKey,
    libraries: 'places,drawing',
    v: '3',
  },
  installComponents: true,
})

export default {
  props: {
    initialSearchValue: {
      type: String,
      default: 'Italy',
    },
  },
  data() {
    return {
      isMapReady: false,
      polygonSelected: null,
      place: null,
      center: { lat: 30, lng: 30 },
      mapObject: null,
      plotUid: null,
    }
  },
  computed: {
    google: VueGoogleMaps.gmapApi,
    plots() {
      const [plot] = this.query('plot', {
        uid: this.plotUid,
      })

      return plot?.coordinates || []
    },
    plotsFormatted() {
      return this.plots.map(plot => {
        return plot.map(coordinate => {
          return new this.google.maps.LatLng(coordinate.lat, coordinate.lng)
        })
      })
    },
    polygons() {
      return this.plotsFormatted.map(plotFormatted => {
        return new this.google.maps.Polygon({
          paths: plotFormatted,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        })
      })
    },
    ...getters(),
  },
  methods: {
    showPolygons() {
      if (this.polygons.length < 1) return
      this.polygons.forEach((polygon) => {
        polygon.setMap(this.mapObject)
      })

      const bounds = new this.google.maps.LatLngBounds()

      // single
      // this.polygons[0].getPath().getArray().forEach(latLng => {
      //   bounds.extend(latLng)
      // })

      this.polygons.forEach(polygon => {
        polygon.getPath().getArray().forEach(latLng => {
          bounds.extend(latLng)
        })
      })

      this.mapObject.fitBounds(bounds)
    },
    async loadMap() {
      const mapObject = await this.$refs.map.$mapPromise
      if (!mapObject) return

      this.mapObject = mapObject
      this.google.maps.event.addListenerOnce(mapObject, 'tilesloaded', () => {
        this.showPolygons()
      })
    },
    async loadPlots() {
      if (!this.plotUid) return

      await this.LOAD(Promise.all([this.FIND_PLOT({
        uid: this.plotUid,
      })]))
    },
    ...actions('FIND_PLOT'),
  },
  async mounted() {
    if (!this.$route.query.id) return

    this.plotUid = this.$route.query.id
    await this.loadPlots()

    this.loadMap()
  },
}
</script>

<style lang="scss">
.PlotViewer {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  .Notice {
    margin: 2rem;
  }
}
</style>

<i18n>
{
  "de": {
    "notFound": ""
  },
  "en": {
    "notFound": "Plot not found"
  },
  "it": {
    "notFound": "Trama non trovata"
  },
  "lt": {
    "notFound": ""
  },
  "pl": {
    "notFound": ""
  }
}
</i18n>
