<template>
  <div class="SectionProjects">
    <CardPortfolioLandowner v-for="portfolio in portfoliosFormated" :key="portfolio.id" isShortSubtitle :portfolio="portfolio" />
  </div>
</template>

<script>
import CardPortfolioLandowner from './CardPortfolioLandowner'
import { getters } from 'views/utils'
import { prop } from 'ramda'

export default {
  components: { CardPortfolioLandowner },
  props: {
    isLandingLandowner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      portfoliosToDisplayPl: [
        {
          id: 57,
          locationOverwrite: 'Gardno',
        },
        {
          id: 51,
          locationOverwrite: 'Komorowo',
        },
        {
          id: 55,
          locationOverwrite: 'Sulechów',
        },
        {
          id: 24,
        },
        // {
        //   id: 27,
        // },
      ],
      portfoliosToDisplayLt: [
        {
          id: 59,
        },
        {
          id: 58,
          capacityOverwrite: 0.497,
        },
        {
          id: 57,
          locationOverwrite: 'Gardno',
        },
        {
          id: 51,
          locationOverwrite: 'Komorowo',
        },
      ],
      locationNamesIt: [
        'Slesia, Polonia',
        'Podlaskie, Polonia',
        'Lubusz, Polonia',
        'Pomerania, Polonia',
      ],
    }
  },
  computed: {
    portfoliosToDisplay() {
      if (this.locale === 'lt') return this.portfoliosToDisplayLt
      if (this.locale === 'pl') return this.portfoliosToDisplayPl
      return []
    },
    limit() {
      if (!this.isLandingLandowner) return 2
      return 4 // this.locale === 'pl' ? 5 : 4
    },
    portfolios() {
      const query = {
        id: {
          $in: this.portfoliosToDisplay.map(prop('id')),
        },
        $limit: this.limit,
      }

      return this.query('portfolio', query)
    },
    portfoliosFormated() {
      if (!this.portfolios || !this.portfolios.length) return []

      return this.portfoliosToDisplay.map(portfolioToDisplay => {
        const portfolio = this.portfolios.find(portfolioItem => portfolioItem.id === portfolioToDisplay.id)
        if (!portfolio) return null

        return {
          ...portfolio,
          capacity: portfolioToDisplay.capacityOverwrite || (portfolio.capacity / 1000).toFixed(0),
          location: portfolioToDisplay.locationOverwrite || portfolio.location,
        }
      }).filter(Boolean)
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.SectionProjects {
  margin: 2rem 0;
}
</style>
