<template>
  <Card class="CardBlogLandowners">
    <Route class="image" :params="{ slug: blog.slug, locale }" tag="div" to="BlogLandownerItem">
      <Pic
        :alt="blog.title"
        draggable="false"
        :src="blog.image.url" />
    </Route>
    <div class="body">
      <div class="meta">
        <div class="date">
          {{ $d(new Date(blog.created_at), 'short') }}
        </div>
      </div>
      <Route class="title" :params="{ slug: blog.slug, locale }" to="BlogLandownerItem">
        {{ blog.title }}
      </Route>
      <div class="preview">
        {{ blog.previewText || blog.content }}
      </div>
    </div>
  </Card>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    blog: {
      type: Object,
      required: true,
    },
  },
  computed: getters(),
}
</script>

<style lang="scss">
.CardBlogLandowners {
  > .image {
    cursor: pointer;
    height: 12rem;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  > .body {
    height: 18rem;
    padding: 2rem;
    position: relative;

    .BtnShare {
      bottom: 1rem;
      position: absolute;
      right: 1rem;
    }

    .more {
      bottom: 2rem;
      position: absolute;
      right: 2rem;
    }

    > a.title {
      @include ellipsis(4);
      color: $text-color;
      font-size: $h5;
      font-weight: $bold;
      line-height: 1.5rem;
      margin: 1.25rem 0 1rem;
      @include md {
        min-height: 6rem;
      }
      @include lg {
        min-height: 3rem;
      }
    }

    .preview {
      @include ellipsis(4);
      // -webkit-box-orient: vertical;
      // display: -webkit-box;
      // -webkit-line-clamp: 2; /* number of lines to show */
      // line-clamp: 2;
      margin: 1rem 0;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "readMore": "Mehr lesen"
  },
  "en": {
    "readMore": "Read more"
  },
  "it": {
    "readMore": "read more"
  },
  "lt": {
    "readMore": "Skaityti daugiau"
  },
  "pl": {
    "readMore": "Pokaż więcej"
  }
}
</i18n>
