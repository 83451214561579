<template>
  <div class="SectionEligibilityLt">
    <div class="body">
      <h3>
        {{ $t('title') }}
      </h3>
      <p v-for="(text, index) in $t('paragraphs')" :key="index" class="text" v-html="text" />
      <Btn @click="scrollToForm">
        {{ $t('cta') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import { scrollToHash } from '../../../../../router/smoothScroll'

export default {
  methods: {
    scrollToForm() {
      scrollToHash('#form')
    },
  },
}
</script>

<style lang="scss">
.SectionEligibilityLt {
  // background-color: $grey-lighter;
  margin: 2rem 0;

  .body {
    padding: 2rem 0;

    .text {
      margin: 1rem 0;
    }

    .Btn {
      margin-top: 1rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "cta": "",
    "paragraphs": [
      "",
      ""
    ],
    "title": ""
  },
  "en": {
    "cta": "",
    "paragraphs": [
      "",
      ""
    ],
    "title": ""
  },
  "it": {
    "cta": "",
    "paragraphs": [
      "",
      ""
    ],
    "title": ""
  },
  "lt": {
    "cta": "Gaukite individualų įvertinimą",
    "paragraphs": [
      "Pagrindinis kriterijus, kurį turi atitikti jūsų žemės sklypas – jo dydis. Nuomai tinkami <b>1 hektaro ploto bei didesni sklypai ir sklypų masyvai.</b> Visais kitais aspektais, tokiais kaip sklypo paskirties bei naudojimo būdo keitimas, pasirūpinsime mes.",
      "Tam, kad galėtumėme detaliau įvertinti jums priklausiančios žemės tinkamumą, prašome susisiekti su mūsų specialistais, kurie visas esamas sąlygas įvertins individualiai."
    ],
    "title": "Ar jūsų žemės sklypas tinkamas?"
  },
  "pl": {
    "cta": "",
    "paragraphs": [
      "",
      ""
    ],
    "title": ""
  }
}
</i18n>
