<template>
  <div class="LandingLandownersItalianEnglish">
    <!-- <MainLogo /> -->
    <TopbarItalian v-if="locale !== 'es'" />
    <!-- <div class="tabs">
      <h5
        v-for="tab in tabs"
        :key="tab.key"
        class="tab"
        :class="{ active: tab.key === tabKeyCurrent }"
        @click="changeTab(tab.key)"
        @keydown="changeTab(tab.key)">
        {{ tab.label }}
      </h5>
    </div> -->

    <Hero :formats="['webp']" src="../../../../assets/landing-landowners/landing-hero.jpg">
      <h1>{{ $t('title') }}</h1>
    </Hero>

    <div v-if="tabKeyCurrent === 'blog'">
      <Wrap class="xl">
        <SectionBlogLandowners />
      </Wrap>
    </div>
    <div v-else-if="tabKeyCurrent === 'projects'">
      <MapPlantsLandowners />
      <SectionAboutSig />
      <!-- <SectionProjectsItalian /> -->
    </div>
    <div v-else>
      <FormContactLandowner />
      <Wrap class="xl">
        <SectionExplanation />
        <SectionEligibility />
      </Wrap>
    </div>

    <Wrap class="xl">
      <SectionValue />
    </Wrap>

    <FormContactLandowner v-if="!isMainTabActive" />
    <SectionFaqLandownersLanding />

    <FooterLandowners />
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import FooterLandowners from 'views/components/FooterLandowners'
import FormContactLandowner from '../FormContactLandowner'
import Hero from 'views/components/Hero'
// import MainLogo from 'views/components/TopBar/MainLogo'
import MapPlantsLandowners from './MapPlantsLandowners'
// import FormContactLandownerSteps from './components/FormContactLandownerSteps'
// import MapLandowner from './components/MapLandowner'
import SectionAboutSig from '../SectionAboutSig'
import SectionBlogLandowners from './SectionBlogLandowners'
import SectionEligibility from '../SectionEligibility'
import SectionExplanation from '../SectionExplanation'
import SectionFaqLandownersLanding from '../SectionFaqLandownersLanding'
// import SectionFeedbacks from './components/SectionFeedbacks'
// import SectionProjectsItalian from './SectionProjectsItalian'
import SectionValue from '../SectionValue'
import TopbarItalian from 'views/components/TopbarItalian'

export default {
  components: {
    FormContactLandowner,
    // FormContactLandownerSteps,
    Hero,
    // MainLogo,
    MapPlantsLandowners,
    SectionAboutSig,
    SectionBlogLandowners,
    SectionEligibility,
    SectionExplanation,
    SectionFaqLandownersLanding,
    // SectionFeedbacks,
    // SectionProjectsItalian,
    SectionValue,
    TopbarItalian,
    FooterLandowners,
    // MapLandowner,
  },
  data() {
    return {
      isDesktopFixed: true,
      tabKeyCurrent: 'offer',
    }
  },
  computed: {
    isMainTabActive() {
      return this.tabKeyCurrent !== 'projects' && this.tabKeyCurrent !== 'blog'
    },
    ...getters('isDesktop'),
  },
  methods: actions('FETCH_BLOG_LANDOWNER', 'FETCH_COUNTRY', 'FETCH_OFFICE', 'FETCH_PORTFOLIO'),
  watch: {
    '$route.query.tab': {
      immediate: true,
      handler(tabKey) {
        // if (!this.tabs.some(tab => tab.key === tabKey)) return
        this.tabKeyCurrent = tabKey
      },
    },
  },
  beforeMount() {
    // this.tabCurrent = this.$route.query.tab || this.tabKeyCurrent
    // this.LOAD()
    this.isDesktopFixed = this.isDesktop
    this.LOAD(Promise.all([
      this.FETCH_BLOG_LANDOWNER(),
      this.FETCH_COUNTRY(),
      this.FETCH_OFFICE(),
      this.FETCH_PORTFOLIO(),
    ]))
  },
}
</script>

<style lang="scss">
.LandingLandownersItalianEnglish {
  font-size: 1.2rem;
  // .MainLogo {
  //   padding: 2rem 0 0 2rem;
  //   position: absolute;
  //   z-index: 2;
  // }
  .Hero {
    min-height: 30vh;

    h1 {
      font-size: $h35;
      font-weight: $semibold;
      padding: 1rem 0;
      text-align: center;
      @include md {
        font-size: $h2;
      }
    }

    .image {
      img {
        filter: brightness(60%);
        height: 100%;
        object-position: top;
      }
    }
  }

  p {
    font-size: 1.2rem;
  }

  // #MapPlants {
  //   margin: 3rem 0;
  // }

  > .SectionAboutSig {
    background-color: $white;
  }
}
</style>

<i18n>
{
  "de": {
    "title": ""
  },
  "en": {

    "title": "Do you want to have a secure income from your unused land?"
  },
  "it": {
    "title": "Vuoi avere una rendita sicura da un tuo terreno inutilizzato?"
  },
  "lt": {

    "title": ""
  },
  "pl": {

    "title": ""
  },
  "es": {
    "title": "¿Quieres optimizar al máximo la rentabilidad de tu terreno?"
  }
}
</i18n>
