<template>
  <div id="LandingLandowners">
    <ContentItalianEnglishSpanish v-if="isItalianEnglishSpanish" />
    <ContentLithuanian v-if="isLithuanian" />
    <ContentPolish v-if="isPolish" />
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import ContentItalianEnglishSpanish from './components/ItalianEnglishSpanish'
import ContentLithuanian from './components/Lithuanian'
import ContentPolish from './components/Polish'

// TODO: remove this - added to recreate MR

export default {
  components: {
    ContentItalianEnglishSpanish,
    ContentLithuanian,
    ContentPolish,
    // MapLandowner,
  },
  data() {
    return {
      isDesktopFixed: true,
    }
  },
  computed: {
    isPolish() {
      return this.locale === 'pl'
    },
    isItalianEnglishSpanish() {
      return this.locale === 'it' ||
        this.locale === 'en' ||
        this.locale === 'es'
    },
    isLithuanian() {
      return this.locale === 'lt'
    },
    ...getters('isDesktop'),
  },
  methods: actions('FETCH_PORTFOLIO'),
  beforeMount() {
    this.LOAD(this.FETCH_PORTFOLIO())
    this.isDesktopFixed = this.isDesktop
  },
}
</script>

<style lang="scss">
#LandingLandowners {
  font-size: 1.2rem;
}
</style>

<i18n>
{
  "de": {
    "title": ""
  },
  "en": {
    "title": "Do you want to secure more than 30 years of rent for your unused land?"
  },
  "it": {
    "title": "Vuoi avere una rendita sicura per oltre 30 anni da un tuo terreno non utilizzato?"
  },
  "lt": {
    "title": ""
  },
  "pl": {
    "title": "Szukasz dodatkowego źródła dochodu na lata?"
  }
}
</i18n>
