<template>
  <div id="LandingLandownersPolish">
    <MainLogo />

    <Hero :formats="['webp']" src="../../../../assets/landing-landowners/hero.jpg">
      <h1>{{ $t('title') }}</h1>
    </Hero>

    <FormContactLandowner />

    <Wrap class="xl">
      <Row>
        <Column :md="8">
          <SectionExplanation />
          <SectionEligibility />
          <!-- <FormContactLandowner v-if="!isDesktop" /> -->
          <!-- <SectionFeedbacks /> -->
          <!-- <FormContactLandownerSteps v-if="!isDesktopFixed && !isLocalePl" /> -->
        </Column>
        <Column :md="4">
          <SectionProjects />
        </Column>
      </Row>
    </Wrap>
    <!-- <MapLandowner /> -->
    <!-- <FormContactLandownerSteps v-if="isDesktopFixed && !isLocalePl" /> -->
    <!-- <FormContactLandowner v-if="isDesktop" /> -->
    <Wrap class="xl">
      <SectionValue />
    </Wrap>

    <SectionFaqLandownersLanding />
    <!-- <FormContactLandowner v-if="isLocalePl" /> -->
    <!-- <FormContactLandowner /> -->
    <SectionNumbers />

    <SectionAboutSig />
    <!-- <Wrap class="xl">
      <SectionContacts />
    </Wrap> -->
    <FooterLandowners />
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import FooterLandowners from 'views/components/FooterLandowners'
import FormContactLandowner from '../FormContactLandowner'
// import FormContactLandownerSteps from '../FormContactLandownerSteps'
// import MapLandowner from '../MapLandowner'
import Hero from 'views/components/Hero'
import MainLogo from 'views/components/TopBar/MainLogo'
import SectionAboutSig from '../SectionAboutSig'
// import SectionContacts from '../SectionContacts'
import SectionEligibility from '../SectionEligibility'
import SectionExplanation from '../SectionExplanation'
import SectionFaqLandownersLanding from '../SectionFaqLandownersLanding'
import SectionNumbers from '../SectionNumbers'
// import SectionFeedbacks from '../SectionFeedbacks'
import SectionProjects from '../SectionProjects'
import SectionValue from '../SectionValue'

export default {
  components: {
    Hero,
    MainLogo,
    FormContactLandowner,
    // FormContactLandownerSteps,
    SectionAboutSig,
    // SectionContacts,
    SectionEligibility,
    SectionExplanation,
    SectionFaqLandownersLanding,
    // SectionFeedbacks,
    SectionProjects,
    SectionValue,
    SectionNumbers,
    FooterLandowners,
    // MapLandowner,
  },
  data() {
    return {
      isDesktopFixed: true,
    }
  },
  computed: {
    isLocalePl() {
      return this.locale === 'pl'
    },
    ...getters('isDesktop'),
  },
  methods: actions('FETCH_PORTFOLIO'),
  beforeMount() {
    this.LOAD(this.FETCH_PORTFOLIO())
    this.isDesktopFixed = this.isDesktop
  },
}
</script>

<style lang="scss">
#LandingLandownersPolish {
  .MainLogo {
    padding: 2rem 0 0 2rem;
    position: absolute;
    z-index: 2;
  }

  .Hero {
    min-height: 30vh;

    h1 {
      font-size: $h3;
      text-align: center;
    }

    .image {
      img {
        height: 100%;
        object-position: center;
      }
    }
  }

  // p {
  //   font-size: 1.5rem;
  // }

  .SectionEligibility {
    .body {
      .content {
        font-size: 1.5rem;

        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "title": ""
  },
  "en": {
    "title": "Do you want to secure more than 30 years of rent for your unused land?"
  },
  "it": {
    "title": "Vuoi avere una rendita sicura per oltre 30 anni da un tuo terreno non utilizzato?"
  },
  "lt": {
    "title": ""
  },
  "pl": {
    "title": "Szukasz dodatkowego źródła dochodu na lata?"
  }
}
</i18n>
