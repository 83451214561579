<template>
  <div class="TopBarItalian">
    <Wrap class="xl">
      <MainLogo />
      <div :aria-label="$t('label')" class="menuLandowners" :class="{ show: showMenu }" role="navigation">
        <!-- <MenuLink
          v-for="(link, index) in menu"
          :index="index"
          :key="index"
          :link="link" /> -->
        <div class="tabs">
          <h5
            v-for="tab in tabs"
            :key="tab.key"
            class="tab"
            :class="{ active: tab.key === tabKeyCurrent }"
            @click="changeTab(tab.key)"
            @keydown="changeTab(tab.key)">
            {{ tab.label }}
          </h5>
        </div>
      </div>
      <div class="landowners-mobile-menu-cont">
        <div
          ref="landowners-hamburger"
          class="landowners-hamburger"
          @click="showMenu = !showMenu"
          @keypress.enter="showMenu = !showMenu">
          <Icon :name="showMenu ? 'cross' : 'bars'" />
        </div>
        <transition name="slide-side">
          <div v-if="showMenu" ref="landownersMobileMenu" class="landowners-mobile-menu" role="navigation">
            <!-- <div class="tabs"> -->
            <h5
              v-for="tab in tabs"
              :key="tab.key"
              class="tab"
              :class="{ active: tab.key === tabKeyCurrent }"
              @click="changeTab(tab.key)"
              @keydown="changeTab(tab.key)">
              {{ tab.label }}
            </h5>
            <!-- </div> -->
          </div>
        </transition>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters, mergeLabels } from 'views/utils'
import MainLogo from 'views/components/TopBar/MainLogo'
// import MenuLink from './MenuLink'
// import { mergeLabels } from 'views/utils'

export default {
  components: { MainLogo },
  data() {
    return {
      showMenu: false,
      // tabKeyDefault: this.$route.name === 'BlogLandownerItem'
      //   ? 'blog'
      //   : 'offer',
      // tabKeyCurrent: this.$route.query.tab || this.tabKeyDefault,
    }
  },
  computed: {
    tabKeyDefault() {
      return this.$route.name === 'BlogLandownerItem'
        ? 'blog'
        : 'offer'
    },
    tabKeyCurrent() {
      return this.$route.query.tab || this.tabKeyDefault
    },
    tabs() {
      return mergeLabels([
        {
          key: 'offer',
        },
        {
          key: 'blog',
        },
        {
          key: 'projects',
        },
      ], this.$ta('tabs'))
    },
    localeMenu() {
      return {
        label: 'En',
        hasIgnoredActive: true,
        children: this
          .locales
          .map(({ label, value }) => ({
            label,
            to: {
              name: this.isPathDynamic(this) ? 'Index' : this.$route.name,
              params: { locale: this.defaultLocale === value ? undefined : value },
            },
          })),
      }
    },
    menu() {
      const appendLabels = ({ children, ...item }, key) => ({
        ...item,
        children: mergeLabels(children, this.$ta(key)),
      })
      const solutions = this.query('solution', { locale: this.locale })
      const [about, portfolio, news, career, contact] = this.staticMenu
      const { screenSizes } = this
      const isTablet = screenSizes.includes('md') && !screenSizes.includes('lg')
      const isWidescreen = screenSizes.includes('xl')
      const isMobile = screenSizes.length === 1
      const isDefaultLocale = this.defaultLocale === this.locale

      const menu = mergeLabels([
        {
          children: solutions.map((solution) => ({
            label: solution.name,
            to: {
              name: 'Solution',
              params: {
                slug: solution.slug,
              },
            },
          })),
        },
        appendLabels(about, 'menu.about'),
        portfolio,
        news,
        {
          ...career,
          doShow: isWidescreen || isMobile,
        },
        appendLabels(contact, 'menu.contact'),
        this.localeMenu,
      ], this.$ta('menu.top'))
        .map((item, index, menuArray) => ({
          ...item,
          doShow: isDefaultLocale || (!isTablet || (!index || index === menuArray.length - 1)),
        }))

      return menu.filter(item => typeof item.doShow === 'undefined' || item.doShow)
    },
    ...getters('defaultLocale', 'isPathDynamic', 'locales', 'screenSizes'),
  },
  methods: {
    changeTab(tabKey) {
      this.$router.replace({
        path: '/it/landing-landowners',
        query: { ...this.$route.query, tab: tabKey },
      })
      this.hideMenu()
    },
    checkClickLocation(event) {
      if (!this.$refs.landownersMobileMenu || !this.showMenu) return

      if (!['landowners-hamburger', 'landownersMobileMenu'].some(ref => this.$refs[ref].contains(event.target))) {
        this.hideMenu()
      }
    },
    hideMenu() {
      this.showMenu = false
    },
    ...actions(),
  },
  async beforeMount() {
    this.$offEvents = await this.ON({
      click: this.checkClickLocation,
    })

    // TODO: fix needed
    // if (!this.$route.query.tab) this.changeTab(this.tabs[0].key)
  },
  beforeDestroy() {
    this.$offEvents && this.$offEvents()
  },
}
</script>

<style lang="scss">
$_burger-width: 2.25rem;

.TopBarItalian {
  position: absolute;
  width: 100%;
  z-index: 7;

  > .Wrap {
    // border-bottom: 1px solid $white;
    padding: 1rem 0.5rem 0.125rem;
    @include xs {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
    @include md {
      border: 0;
    }
    @include xl {
      padding: 1rem 2rem 0.125rem;
    }
  }

  .MainLogo {
    // padding: 2rem 0 0 2rem;
    position: absolute;
    z-index: 2;
  }

  .tabs {
    display: inline-block;
    margin-right: 1rem;
    right: 0;
    z-index: 2;
    // @include lg {
    //   padding: 2rem 2rem 0 0;
    // }
  }

  .tab {
    color: $white;
    font-size: 1.2rem;
    font-weight: $semibold;
    @include md {
      display: inline-block;
      font-size: 1.2rem;
      padding-right: 3rem;
    }
    // padding-bottom: 2rem;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &.active {
      text-decoration: underline;
    }
  }
}

.menuLandowners {
  color: $white;
  display: none;
  float: right;
  padding: 2.5rem 0;
  @include md {
    display: block;
    padding: 1rem 0;
  }
  @include xl {
    padding: 2rem 0;
  }
}

.landowners-mobile-menu-cont {
  float: right;
  @include md {
    display: none;
  }
}

.landowners-mobile-menu {
  align-items: center;
  background: $black;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  left: 20%;
  padding: 20% 0 80%;
  position: fixed;
  top: 0;
  width: 80%;
  z-index: 10;
}

.landowners-hamburger {
  background: $white;
  border-radius: $radius;
  color: $black-ter;
  cursor: pointer;
  float: right;
  font-size: 2rem;
  height: $_burger-width;
  line-height: $_burger-width;
  margin-top: 0.5rem;
  position: relative;
  text-align: center;
  width: $_burger-width;
  z-index: 11;
  @include maxSm {
    display: inline-block;
  }
}

// @animation
.slide-side-enter-active,
.slide-side-leave-active {
  @include trans;
  opacity: 1;
  transform: translateX(0);
}

.slide-side-enter,
.slide-side-leave-to {
  opacity: 0;
  transform: translateX(300px);
}
</style>

<i18n>
{
  "de": {
    "label": "",
    "menu": {
      "about": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ],
      "contact": [
        "",
        ""
      ],
      "top": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ]
    },
    "tabs": [
      "",
      "",
      ""
    ]
  },
  "en": {
    "label": "Main navigation",
    "menu": {
      "about": [
        "About us",
        "Quick facts",
        "Our focus",
        "Bussiness model",
        "Philosophy",
        "Management",
        "Group"
      ],
      "contact": [
        "Contact Us",
        "Offices"
      ],
      "top": [
        "Solutions",
        "About us",
        "Portfolio",
        "News & Press",
        "Career",
        "Contact",
        "En"
      ]
    },
    "tabs": [
      "Request an offer",
      "Blog",
      "Projects"
    ]
  },
  "it": {
    "label": "",
    "menu": {
      "about": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ],
      "contact": [
        "",
        ""
      ],
      "top": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ]
    },
    "tabs": [
      "Richiedi la tua offerta",
      "Blog",
      "Progetti"
    ]
  },
  "lt": {
    "label": "Meniu",
    "menu": {
      "about": [
        "Apie mus",
        "Faktai",
        "Mūsų tikslas",
        "Verslo modelis",
        "Vertybės",
        "Vadovybė",
        "Grupė"
      ],
      "contact": [
        "Susisiekite su mumis",
        "Biurai"
      ],
      "top": [
        "Sprendimai",
        "Apie mus",
        "Įgyvendinti projektai",
        "Naujienos ir spauda",
        "Karjera",
        "Kontaktai",
        "Lt"
      ]
    },
    "tabs": [
      "",
      "",
      ""
    ]
  },
  "pl": {
    "label": "Główna nawigacja",
    "menu": {
      "about": [
        "O nas",
        "SIG w liczbach",
        "Nasz cel",
        "Model biznesowy",
        "Filozofia",
        "Kadra zarządzająca",
        "Grupa"
      ],
      "contact": [
        "Skontaktuj się z nami",
        "Biura"
      ],
      "top": [
        "Rozwiązania",
        "O nas",
        "Zrealizowane projekty",
        "Komunikaty prasowe",
        "Kariera",
        "Kontakt",
        "Pl"
      ]
    },
    "tabs": [
      "",
      "",
      ""
    ]
  },
  "es": {
    "label": "",
    "menu": {
      "about": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ],
      "contact": [
        "",
        ""
      ],
      "top": [
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ]
    },
    "tabs": [
      "Solicita tu oferta",
      "Blog",
      "Proyectos"
    ]
  }
}
</i18n>
