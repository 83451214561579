<template>
  <FieldValidation #default="{ classes }" class="MultiSelect-cont" required :rules="rulesDerived">
    <!-- <FieldLabel v-if="fieldLabel" :for="inputId" :required="$attrs.required">
      {{ fieldLabel }}
    </FieldLabel> -->
    <label v-if="fieldLabel" :for="inputId">
      {{ fieldLabel }}
      <span v-if="required" class="required">
        *
      </span>
    </label>
    <Multiselect
      ref="select"
      v-bind="{
        customLabel,
        deselectLabel,
        loading,
        multiple,
        selectLabel,
        selectedLabel,
        trackBy,
        ...$props,
        ...$attrs,
      }"
      :allowEmpty="false"
      class="MultiSelect"
      :class="classes"
      :options="optionsFormatted"
      :value="valueFormatted"
      v-on="$listeners"
      @close="$emit('close')"
      @remove="remove"
      @select="add">
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
        :name="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData">
        <slot v-bind="slotData" :name="name" />
      </template>
    </Multiselect>
  </FieldValidation>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import FieldLike from 'views/mixins/FieldLike'
import FieldValidation from './FieldValidation'
import Multiselect from 'vue-multiselect'

const primitiveTypes = ['string', 'number']
const randomElementId = (() => {
  const beginningDigits = /^\d+/

  return () => Math
    .random()
    .toString(36)
    .slice(2, 15)
    .replace(beginningDigits, '')
})()

export default {
  mixins: [FieldLike],
  components: { Multiselect, FieldValidation },
  props: {
    customLabel: { type: Function, default: undefined },
    deselectLabel: { type: String, default: '' },
    fieldLabel: { type: String, default: undefined },
    loading: { type: Boolean, default: false },
    label: { type: String, default: 'label' },
    multiple: { type: Boolean, default: true },
    options: Array,
    selectedLabel: { type: String, default: '' },
    selectLabel: { type: String, default: '' },
    trackBy: { type: String, default: 'value' },
    value: null,
  },
  data() {
    return {
      inputId: randomElementId(),
    }
  },
  computed: {
    hasEntriesOptions() {
      return this.hasOptionArray && (Array.isArray(this.options[0]) || this.hasPrimitiveOptions)
    },
    hasOptionArray() {
      return Array.isArray(this.options) && this.options.length
    },
    hasPrimitiveOptions() {
      return this.hasOptionArray &&
        this.options.some(option => primitiveTypes.includes(typeof option))
    },
    optionsFormatted() {
      const preOptions = this.hasPrimitiveOptions
        ? this.options.map(option => Array.isArray(option) ? option : [option, option])
        : this.options

      return this.hasEntriesOptions
        ? preOptions.map(entryToObject)
        : preOptions
    },
    valueFormatted() {
      const { value: valueRaw } = this

      if (!this.hasEntriesOptions) return valueRaw

      if (this.multiple) {
        if (!Array.isArray(valueRaw)) throw new Error('Expected Array MultiSelect value')

        return valueRaw
          .map(value => this
            .optionsFormatted
            .find(option => option.value === value))
      }

      return this
        .optionsFormatted
        .find(option => option.value === valueRaw)
    },
  },
  methods: {
    add(item) {
      // this.$emit('select', this.formatOut(item))

      // const value = this.multiple
      //   ? [...this.valueFormatted, item]
      //   : item

      this.$emit('update:value', item.value)

      // this.$emit('update:value', this.formatOut(value))
    },
    remove(item) {
      const { trackBy } = this
      const itemValue = this.hasEntriesOptions ? item.value : item[trackBy]
      const ommiter = option => option[trackBy] !== itemValue

      this.$emit('remove', this.formatOut(item))

      const value = this.multiple
        ? this.valueFormatted.filter(ommiter)
        : null

      this.$emit('update:value', this.formatOut(value))
    },
    formatOut(valueFormatted) {
      if (valueFormatted === null) return null
      if (!this.hasEntriesOptions) {
        return valueFormatted
      }

      return Array.isArray(valueFormatted)
        ? valueFormatted.map(objectToValue)
        : objectToValue(valueFormatted)
    },
  },
}

const entryToObject = ([value, label]) => ({ label, value })
const objectToValue = object => object.value
</script>

<style lang="scss">
$_height: 2.5rem;

.MultiSelect-cont {
  line-height: 2.5rem;
  margin-bottom: 4rem;
  position: relative;
  @include md {
    font-size: $h5;
    line-height: 1.5rem;
    margin-bottom: 4rem;
  }

  label,
  label.field-label {
    @include trans;
    color: inherit;
    display: block;
    font-size: $h7;
    font-weight: $semibold;
    left: 0.5rem;
    line-height: 1rem;
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: 0.25rem;
    @include md {
      line-height: inherit;
    }
  }
}

.MultiSelect {
  color: $black;
  line-height: $_height;
  min-height: $_height;

  .touched.unchanged {
    background: $red;
  }

  .multiselect__select {
    background: transparent;
    height: 88%; // NOTE: hackish

    &::before {
      z-index: 1;
    }
  }

  .multiselect__selected {
    background: $main;
    color: $white;

    span {
      color: $red;
    }
  }

  .multiselect__option--selected {
    color: $red;
  }

  .multiselect__tags-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.3rem;
  }

  .multiselect__tags {
    background: $white;
    border-color: $black;
    // border-radius: $radius-lg;
    font-size: 1rem;
    min-height: $_height;
    padding: 0 0.5rem;
  }

  .multiselect__tag {
    background-color: $main;
    line-height: 1.75rem;
  }

  .multiselect__single {
    background: $info-light;
    // color: inherit;
    color: $black;
    cursor: pointer;
    line-height: inherit;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .multiselect__placeholder {
    margin-bottom: 0;
    padding-top: 0;
  }

  .multiselect__input {
    background: $white;
    border: none;
    color: $black;
    line-height: inherit;
    margin-bottom: 0;
  }

  .multiselect__tag-icon {
    height: 2.25rem;
    line-height: 2.25rem;

    // &::after {
    //   color: $white;
    // }

    &:hover {
      background-color: $danger-light;
      color: $danger;

      &::after {
        color: inherit;
      }
    }
  }

  .multiselect__option {
    border-bottom: 1px solid $border-color;
    color: $black;

    span {
      color: $black;
    }
    // padding: $grid-unit-half;
  }

  .multiselect__option--highlight,
  .multiselect__option--highlight::after {
    background: $info-light;
    color: $black;
  }

  .multiselect__content-wrapper {
    @include customScroll;
    // background: $input-background;
  }

  .multiselect__content {
    width: 100%;
  }

  &.invalid {
    .multiselect__tags {
      border-color: $danger;
    }
  }
}
</style>
