<template>
  <div id="FooterLandowners">
    <Wrap>
      <div class="top">
        <div class="logo">
          <Pic loading="lazy" src="../../assets/logo.svg" />
          <div v-if="locale === 'it' || locale === 'en'" class="certificate">
            <Pic :alt="$t('certificateAlt')" :formats="['webp']" src="../../assets/logo-top40.png" />
            <Pic :alt="$t('certificateAlt')" class="short" :formats="['webp']" src="../../assets/logo-italia-solare.png" />
          </div>
          <!-- <div v-if="locale !== 'pl' && isDesktop" class="social">
            <SocialIcon
              v-for="({ href, icon, title }) in socials"
              :href="href"
              :key="title"
              :icon="icon"
              :title="title" />
          </div> -->
        </div>
        <div class="contacts">
          <Row>
            <Column :md="6" :xl="6" :xs="0" />
            <!-- <Column :md="7" :xl="7">
              <MapFooterLandowners />
            </Column> -->
            <Column class="content" :md="6" :xl="6">
              <div class="details">
                <div class="title">
                  {{ $t('contactsTitle') }}
                </div>
                <ul>
                  <li v-if="$t('contacts.phone') !== 'contacts.phone'">
                    <Phone :number="$t('contacts.phone')" @click="fireFbContactEvent" />
                    <Icon name="phone" />
                  </li>
                  <li v-if="$t('contacts.email') !== 'contacts.email'">
                    <Email :address="$t('contacts.email')" @click="fireFbContactEvent" />
                    <Icon name="envelope" />
                  </li>
                  <li v-if="$t('contacts.office') !== 'contacts.office'">
                    <div> {{ $t('contacts.office') }} </div>
                    <Icon name="building" />
                  </li>
                </ul>
              </div>
              <div v-if="locale === 'it' || locale === 'en' || locale === 'es'" class="mapContainer">
                <MapFooterLandowners />
              </div>
            </Column>
          </Row>
        </div>
      </div>
      <div class="bottom">
        <Row>
          <Column :lg="4" />
          <Column :lg="4">
            <div class="social">
              <SocialIcon
                v-for="({ href, icon, title }) in socials"
                :key="title"
                :href="href"
                :icon="icon"
                :title="title" />
            </div>
            <div class="copyright">
              <div>
                © {{ new Date().getFullYear() }} Sun Investment Group
              </div>
              <router-link :to="`/${locale}/privacy-policy`">
                {{ $t('policy') }}
              </router-link>
            </div>
          </Column>
          <div v-if="locale === 'pl'" class="disclaimer">
            {{ $t('disclaimer') }}
          </div>
        </Row>
      </div>
    </Wrap>
  </div>
</template>

<script>
import MapFooterLandowners from 'views/components/MapFooterLandowners'
import SocialIcon from 'views/components/SocialIcon'
import { getters } from '../utils'
import { webUrl } from '../../config'

export default {
  components: { SocialIcon, MapFooterLandowners },
  data() {
    return {
      certificatePath: `${webUrl}/assets/certificates/SIG.jpg`,
      doShowCertificate: false,
      socials: [
        {
          href: 'https://www.facebook.com/sunInvestmentgroup/',
          icon: 'facebook',
          label: 'Facebook',
        },
        {
          href: 'https://www.linkedin.com/company/suninvestmentgroup/',
          icon: 'linkedin',
          label: 'LinkedIn',
        },
        {
          href: 'https://www.youtube.com/channel/UCcOLvuZbV0Cx_m4chjEmicQ',
          icon: 'youtube',
          label: 'Youtube',
        },
        {
          href: 'https://www.instagram.com/suninvestmentgroup/',
          icon: 'instagram',
          label: 'Instagram',
        },
      ],
    }
  },
  computed: {
    careerCount() {
      return this
        .query('career')
        .length
    },
    ...getters('screenSizes', 'isDesktop'),
  },
  methods: {
    fireFbContactEvent() {
      this.$analytics.fbq.event('Contact')
    },
  },
}
</script>

<style lang="scss">
#FooterLandowners {
  background: $black;
  color: $white;
  font-weight: $semibold;
  padding-top: 8rem;

  .Wrap {
    padding: 0 2.5rem;

    .top {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      @include md {
        flex-direction: row;
      }

      > .logo {
        text-align: center;
        @include md {
          text-align: left;
        }

        > .certificate {
          display: block;
          margin: 4rem 0 0;

          // text-align: center;

          img {
            cursor: pointer;
            height: 185px;
            @include xl {
              margin: 4rem 0 0;
            }
          }

          .short {
            display: block;

            img {
              cursor: pointer;
              height: 85px;
              margin: 4rem 0 0;
              @include xl {
                margin: 4rem 0 0;
              }
            }
          }
        }

        img {
          height: 5.5rem;
          @include md {
            height: 4.5rem;
          }
        }

        .social {
          display: flex;
          justify-content: center;
          // margin: 1rem 0;
          @include md {
            justify-content: flex-end;
            margin-top: 5rem;
          }
          // .SocialIcon {
          //   margin: 0 0 0 1rem;
          // }
        }
      }

      .contacts {
        flex-grow: 1;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .title {
          display: block;
          font-size: 1.3rem;
          margin: 1.5rem 0;
          opacity: 0.4;
          @include md {
            margin-top: 0;
          }
        }

        .Row {
          height: 100%;

          > div {
            text-align: center;
            @include md {
              text-align: right;
            }
          }

          ul {
            list-style: none;

            > li {
              font-size: 1.3rem;
              margin-bottom: 1.5rem;

              .Icon {
                margin-left: 0.5rem;
              }
            }
          }
        }

        .details {
          > ul {
            > li {
              > div {
                display: inline;
              }
            }
          }
        }

        .mapContainer {
          display: flex;
          justify-content: center;
          margin-bottom: 2rem;
          @include md {
            justify-content: flex-end;
            margin-bottom: unset;
          }
        }
      }
    }
  }

  .bottom {
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 4rem;

    > .Row {
      width: 100%;

      > div {
        padding: 0.5rem;
        text-align: center;

        &:first-child {
          @include lg {
            text-align: left;
          }
        }
      }
    }

    .social {
      margin: 1rem 0;
    }

    .copyright {
      color: $white;
      flex-grow: 2;
      font-weight: $regular;
      text-align: center;

      > div {
        margin-bottom: 0.5rem;
        opacity: 0.4;
      }

      > a {
        color: $white;
      }
    }

    .disclaimer {
      font-size: 0.5rem;
      font-weight: $regular;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "certificateAlt": "",
    "contacts": {
      "email": "",
      "office": "",
      "phone": ""
    },
    "contactsTitle": "",
    "disclaimer": "",
    "policy": ""
  },
  "en": {
    "certificateAlt": "Among the top 50 photovoltaic companies in Europe",
    "contacts": {
      "email": "sviluppo@sigitaly.com",
      "office": "Viale A. Masini 12, 40126, Bologna",
      "phone": ""
    },
    "contactsTitle": "Get in touch with our team",
    "disclaimer": "",
    "policy": "Privacy Policy"
  },
  "it": {
    "certificateAlt": "Tra le prime 50 aziende del fotovoltalco in Europa",
    "contacts": {
      "email": "sviluppo@sigitaly.com",
      "office": "Viale A. Masini 12, 40126, Bologna",
      "phone": ""
    },
    "contactsTitle": "Contatta il nostro Team",
    "disclaimer": "",
    "policy": "Politica Sulla Riservatezza"
  },
  "lt": {
    "certificateAlt": "",
    "contacts": {
      "email": "Jst@eterniasolar.lt",
      "office": "Gedimino pr. 44A-501, Vilnius",
      "phone": "+370 6877 8414"
    },
    "contactsTitle": "Susisiekite su mumis",
    "disclaimer": "",
    "policy": "Privatumo politika"
  },
  "pl": {
    "certificateAlt": "",
    "contacts": {
      "email": "gliwice@sigziemia.pl",
      "office": "Zygmunta Starego 11A 44-100 Gliwice, Polska",
      "phone": "884 202 788"
    },
    "contactsTitle": "Skontaktuj się z nami",
    "disclaimer": "SUN INVESTMENT DEVELOPMENT PL sp. z o.o. z siedzibą w Warszawie, pod adresem: Plac Marszałka Józefa Piłsudskiego 2, 00-073 Warszawa, Polska, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla Miasta Stołecznego Warszawy, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000646954, NIP: 5223075888, REGON: 365864670, kapitał zakładowy: 5.000,00 zł w całości wpłacony",
    "policy": "Polityka prywatności"
  },
  "es": {
    "contacts": {
      "email": "desarrollo@suninvestmentgroup.com",
      "office": "Paseo de la Castellana 182. 9ª Planta Madrid",
      "phone": ""
    },
    "contactsTitle": "Póngase en contacto con nuestro equipo",
    "disclaimer": "",
    "policy": "Política de Privacidad"
  }
}
</i18n>
