const translations = {
  en: {
    faqSections: [
      {
        title: 'Frequently asked questions about operational land lease',
        faq: [
          {
            q: 'What Sun Investment Group / SIG does?',
            a: 'Sun Investment Development deals in leasing land for solar farms.',
          },
        ],
      },
    ],
  },
  it: {
    faqSections: [
      {
        title: 'Domande frequenti sull’affitto di terreno per la realizzazione di impianti fotovoltaici',
        faq: [
          {
            q: 'Qual è il periodo di locazione?',
            a: 'La durata del contratto di locazione è di minimo 25 anni, prorogabili.',
          },
          {
            q: 'E\' possibile leggere il Contratto di locazione?',
            a: 'Il contratto di locazione è preparato individualmente, sulla base della caratteristiche del terreno. Contattateci per un\'analisi e una valutazione personalizzata.',
          },
          {
            q: 'Quanto riceverò per il contratto di locazione?',
            a: 'Ogni caso è considerato singolarmente. Teniamo conto, tra l\'altro, della dimensione del terreno, dell\'ubicazione, della struttura del suolo, della vicinanza di centrali elettriche, della presenza di ostacoli, ecc.',
          },
          {
            q: 'Sun Investment Group potrebbe essere interessato a comprare dei terreni?',
            a: 'Siamo disposti a considerare la possibilità di comprare dei terreni così come di affitarli.',
          },
          {
            q: 'L\'ammontare annuo del canone di locazione è indicizzato?',
            a: 'Sì, l\'ammontare del canone è indicizzato in base ai valori ISTAT.',
          },
          {
            q: 'Cosa succede alla fine del contratto, per quanto riguarda i pannelli e l\'impianto?',
            a: 'Sun Investment Group si occupa in modo completo di tutti i progetti. Dopo il contratto, smantelleremo l\'impianto e riporteremo il terreno alle condizioni originali, sostenendo tutti i costi associati.',
          },
          {
            q: 'Quali sono i rischi degli impianti fotovoltaici?',
            a: 'Un impianto fotovoltaico costruito utilizzando moduli in silicio cristallino (come fa Sun Investment Group) è sicuro sia per le persone che per l\'ambiente, e per legge viene costruito nel rispetto di standard molto severi, che vengono sempre meticolosamente rispettati. Nel suo funzionamento non produce alcun tipo di rifiuto/scarto (niente acque reflue, niente plastica, niente rumore) e non è una fonte di radiazioni elettromagnetiche. Non funziona peraltro di notte, e di conseguenza non crea disturbo alle persone o agli animali notturni.',
          },
          {
            q: 'Che tipo di manutenzione verrà effettuata?',
            a: 'Un impianto fotovoltaico richiede relativamente poca manutenzione, sia elettrica che meccanica, che viene comunque effettuata solamente da personale certificato e specializzato. Con regolarità viene effettuato il lavaggio dei pannelli solari, ma con acqua demineralizzata e senza utilizzo di alcun detergente a tutela del suolo e dei pannelli solari stessi. Secondo la stagionalità vengono inoltre effettuati dei tagli dell\'erba, che viene solitamente lasciata sul terreno per mantenere la ricchezza del substrato e non impoverire l\'area in previsione dello smantellamento a fine progetto.',
          },
          {
            q: 'Che dire degli animali?',
            a: 'Per la protezione dell\'impianto fotovoltaico vengono utilizzate recinzioni che consentono la migrazione normale dei piccoli animali (ricci, serpenti, ecc). I moduli hanno anche un rivestimento antiriflesso, che evitano agli uccelli problemi di accecamento. Non vi è dunque alcuna minaccia per gli animali, sia per quelli selvatici che per quelli di fattoria.',
          },
          {
            q: 'Che dire delle piante?',
            a: 'Per il mantenimento della vegetazione al di sotto del livello dei moduli fotovoltaici e per prevenire eventuali incendi nel periodo estivo, non vengono utilizzati fertilizzanti, nutrienti o erbicidi (tipo Roundup). Viene utilizzato solo lo sfalcio manuale rendendo possible il pascolo di pecore o altri animali idonei allo scopo (oche, anatre, ecc). Non viene quindi causata contaminazione o inquinamento del suolo, ma anzi si cerca di valorizzare le realtà agricole locali.',
          },
        ],
      },
    ],
  },
  pl: {
    faqSections: [
      {
        title: 'Najczęściej zadawane pytania',
        faq: [
          {
            q: 'Czym zajmuję się firma Sun Investment Group/SIG?',
            a: 'Sun Investment Development zajmuje się wydzierżawianiem gruntów pod budowę farm fotowoltaicznych. Realizujemy Politykę Energetyczną poprzez konstrukcję i rozwój instalacji. Projekty prowadzimy od początku do końca, czyli od momentu podpisania umowy dzierżawy, aż do momentu finalizacji inwestycji. W Polsce zrealizowaliśmy już 109 projektów farm fotowoltaicznych, aktualnie pracujemy nad kolejnymi, o łącznej mocy ponad 1.4 GW.',
          },
          {
            q: 'Jakie wymagania musi spełniać działka, żeby można ją było wydzierżawić pod instalację farmy fotowoltaicznej?',
            a: 'Najważniejsze, aby działka spełniała następujące warunki:\n\na) powierzchnia terenu – min. 10 ha,\n\nb) działki bez planu zagospodarowania przestrzennego lub z planem pod fotowoltaikę/produkcję,\n\nc) niskie klasy gruntów (IV, V, VI) oraz nieużytki.\n\nKoniecznie jest także, aby działka posiadała księgę wieczystą. W przypadku gdy jej nie ma pomagamy w jej założeniu. Pozostałe aspekty sprawdzane są przez odpowiedni dział w naszej firmie.',
          },
          {
            q: 'Czy jeśli mój grunt ma mniej niż 1,2 ha mogę oddać go w dzierżawę dla SIG?',
            a: 'Jest taka możliwość, jeżeli na działce oraz na nieruchomości znajdującej się w bezpośrednim sąsiedztwie, nie znajdują się żadne przeszkody planistyczne (ciek wodny, rów, linia energetyczna, drzewa, zabudowania), wtedy możemy zrealizować projekt na powierzchni ok. 1,2 ha.  W przeciwnym razie nic straconego - zawsze można przekonać sąsiada i wejść we współpracę z nami, razem z nim.',
          },
          {
            q: 'Czy klasa gruntu ma znaczenia?',
            a: 'Tak. Interesują nas jedynie grunty słabej klasy (IV, V, VI) oraz nieużytki.',
          },
          {
            q: 'Moje pola leżą na wzgórzu, czy to dobry grunt?',
            a: 'Tego typu kwestie rozpatrujemy indywidualnie. Prosimy o kontakt z naszą firmą pod adresem: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub numerem telefonu: [884 202 788](tel:884202788), a zweryfikujemy jakie są nasze możliwości.',
          },
          {
            q: 'Na mojej działce znajduje się las, czy taki obszar się nadaje?',
            a: 'Drzewa oraz budynki mogą negatywnie wpływać na efektywność instalacji fotowoltaicznej, poprzez jej zacienianie, dlatego instalacja musi być od nich odsunięta około 30-40 metrów. Prosimy o kontakt z naszą firmą pod adresem: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub numerem telefonu: [884 202 788](tel:884202788), a zweryfikujemy jakie są możliwości.',
          },
          {
            q: 'Moja nieruchomość znajduje się w obszarze Natura2000, czy jest to problem?',
            a: 'Wydzierżawiamy działki znajdujące się w obszarze Natura2000, jeżeli ich powierzchnia to minimum 6 hektarów.',
          },
          {
            q: 'Działka znajduje się w Obszarze Chronionego Krajobrazu, może być?',
            a: 'Zazwyczaj tak, prosimy o kontakt z naszymi specjalistami pod adresem: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub numerem telefonu: [884 202 788](tel:884202788), a zweryfikujemy jakie są nasze możliwości.',
          },
          {
            q: 'Jak mogę się do Was zgłosić?',
            a: 'Zgłoszenia można dokonać telefonicznie: [884 202 788](tel:884202788) lub mailowo: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub [poprzez zakładkę](/pl/landing-landowners#form).',
          },
          {
            q: 'Jaki jest okres dzierżawy?',
            a: 'Czas dzierżawy to 29 lat i jest to punkt umowy, który nie podlega negocjacjom. Ten okres związany jest m.in. z gwarancją modułów fotowoltaicznych, która wynosi 25 lat.',
          },
          {
            q: 'Jak wygląda umowa, gdzie mogę ja zobaczyć?',
            a: 'Umowa jest przygotowywana indywidualnie, prosimy o kontakt telefoniczny: [884 202 788](tel:884202788) lub mailowy: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl), a wszystko zostanie ustalone.',
          },
          {
            q: 'Ile dostanę za dzierżawę?',
            a: 'Każdy przypadek rozpatrywany jest indywidualnie. Bierzemy pod uwagę m.in. występowanie przeszkód planistycznych, dostęp do drogi czy odległość od linii energetycznych. Zapraszamy do kontaktu: [884 202 788](tel:884202788) lub mailowo: gliwice@sigziemia.pl, a dokonamy szczegółowej wyceny.',
          },
          {
            q: 'Kiedy będą otrzymywał pieniądze?',
            a: 'Oferujemy dwa różne czynsze dzierżawne, wypłacane zawsze za rok z góry lub z dołu. Pierwszy z nich płacony jest od momentu podpisania umowy do czasu rozpoczęcia budowy. Drugi, wyższy czynsz właściciel nieruchomości otrzymuje co roku podczas eksploatacji instalacji aż do czasu jej rozbiórki.',
          },
          {
            q: 'Czy roczna kwota czynszu dzierżawnego jest waloryzowana?',
            a: 'Tak, kwota czynszu jest waloryzowana według wskaźnika GUS.',
          },
          {
            q: 'Co dzieje się po zakończeniu umowy, co z panelami?',
            a: 'Sun Investment Group kompleksowo zajmuje się wszystkimi projektami. Po zakończeniu umowy demontujemy farmę i przywracamy ziemię do pierwotnego stanu. Ponosimy wszystkie związane z tym koszty, jak również koszty recyklingu całej instalacji.',
          },
          {
            q: 'Co zyskam podpisując z Wami umowę?',
            a: 'Współpraca z nami to możliwość posiadania dodatkowego i stabilnego źródła dochodu. Po podpisaniu umowy. Dzierżawca ma stały kontakt z kierownikiem projektu, który nadzoruje proces administracyjny (decyzje o uwarunkowaniach środowiskowych, warunki zabudowy itp.). Dodatkowo, wspólnie przyczyniamy się do rozwoju „zielonej” energii w naszym kraju, dzięki której w przyszłości będziemy mogli oddychać czystszym powietrzem.',
          },
          {
            q: 'Co ludzie zyskują dzięki fotowoltaice?',
            // eslint-disable-next-line xss/no-mixed-html
            a: 'Fotowoltaika to ekologiczne i bezpieczne rozwiązanie, które pozwala produkować prąd. Dzięki zastosowaniu fotowoltaiki zmniejszamy ilość produkowanego CO<sub>2</sub> oraz innych zanieczyszczeń a co za tym idzie smogu, który staje się w Polsce coraz poważniejszym problemem. Zanieczyszczenia powietrza, z którymi zmagamy się na co dzień wpływają źle nie tylko na nasze samopoczucie, ale i zdrowie, w sposób szczególny narażając dzieci i osoby starsze.',
          },
          {
            q: 'Jakie są zagrożenia z fotowoltaiki?',
            a: 'Fotowoltaika to bezpieczna metoda produkcji energii elektrycznej, która nie stwarza zagrożeń dla otoczenia. Jest bezpieczna zarówno dla ludzi jak i środowiska naturalnego. Budowa farm fotowoltaicznych wymaga spełnienia odpowiednich, bardzo surowych norm, które są zawsze są pieczołowicie wypełniane przez naszą firmę. Parametry, które uzyskujemy nie tylko są zgodne z obowiązującymi normami, ale również znajdują się znacznie poniżej górnych limitów.\n\nFarma fotowoltaiczna jest praktycznie bezobsługowa. Nie ma tutaj mowy również o ściekach. Moduły zamontowane są pod kątem i obmywane są przez deszcz. W przypadku jego braku, wyspecjalizowana firma umyje je wodą - bez użycia detergentów, nie powodujemy więc żadnych zanieczyszczeń gruntu. Elektrownia słoneczna nie jest także źródłem istotnego promieniowania elektromagnetycznego, większe wartości promieniowania emitują telefony komórkowe, które mamy cały czas przy sobie. Jeśli chodzi o hałas, to jedynym jego źródłem może być transformator, ukryty w stacji transformatorowej, jest on jednak zawsze od terenów chronionych akustycznie. Farma fotowoltaiczna nie pracuje w nocy, a wszelkie badania przeprowadzone na istniejących instalacjach wykazały, że tego typu instalacja nie przekracza żadnych dopuszczalnych norm hałasu.',
          },
          {
            q: 'A co ze zwierzętami?',
            a: 'Nasze ogrodzenie podniesione jest od gruntu, dzięki temu migracja drobnych zwierząt nie jest zakłócona. Moduły posiadają również powłokę antyrefleksyjną, zatem ptaki nie zostaną oślepione, ani nie pomylą instalacji z taflą wody. W żaden sposób nie zagrażamy więc zwierzętom, zarówno dzikim jak i gospodarskim.',
          },
          {
            q: 'A co z roślinami?',
            a: 'Powierzchnia pomiędzy stołami, na których zamontowane są moduły jest wciąż powierzchnią biologicznie czynną. Po zakończeniu budowy obszar ten zasiewamy trawami lub zostawiamy do porośnięcia przez lokalną roślinność, w zależności od otrzymanej decyzji o uwarunkowaniach środowiskowych.\n\nSame moduły montowane są na wysokości min. 1,2 m, dlatego wystarczy, gdy teren inwestycji wykosimy raz czy dwa razy w roku - zadaniem tym zajmuje się kompleksowo nasza firma. Co ważne, nie używamy, żadnych nawozów, odżywek, herbicydów (typu Roundup), itp. nie powodujemy więc żadnego skażenia czy zanieczyszczenia gleby.',
          },
          {
            q: 'Czy farma jest ubezpieczona?',
            a: 'Oczywiście, farma fotowoltaiczna jest dużą inwestycją i musi zostać ubezpieczona. Sun Investment Group zajmuje się inwestycją kompleksowo inwestycją, pokrywamy więc również koszty ubezpieczenia i ponosimy koszty wszelkich napraw itp.',
          },
          {
            q: 'Chce zobaczyć farmę na żywo, gdzie mogę ją zobaczyć?',
            a: 'Zdjęcia projektów zrealizowanych już w Polsce przez naszą firmę, wraz z ich lokalizacjami znajdują się na naszej stronie: [https://suninvestmentgroup.com/portfolio](https://suninvestmentgroup.com/portfolio). Dzięki podanym lokalizacjom łatwo odnaleźć je w konkretnych miejscowościach, gdzie można zobaczyć je na żywo.',
          },
        ],
      },
    ],
  },
  es: {
    faqSections: [
      {
        title: 'Preguntas frecuentes sobre el arrendamiento de terrenos para la construcción de sistemas fotovoltaicos',
        faq: [
          {
            q: '¿Cuál es el período de alquiler?',
            a: 'La duración del contrato de arrendamiento es de al menos 30 años, prorrogables.',
          },
          {
            q: '¿Es posible leer el Contrato de Arrendamiento?',
            a: 'El contrato de arrendamiento se prepara individualmente, en función de las características del terreno. Contáctenos para un análisis y evaluación personalizada.',
          },
          {
            q: '¿Cuánto recibiré por el arrendamiento?',
            a: 'Cada caso se considera individualmente. Tenemos en cuenta, entre otras cosas, el tamaño del terreno, la ubicación, las propiedades del suelo, la proximidad de centrales eléctricas, la presencia de obstáculos, etc.',
          },
          {
            q: '¿Podría Sun Investment Group estar interesado en comprar terrenos?',
            a: 'Estamos dispuestos a considerar la posibilidad de comprar un terreno así como también arrendarlo.',
          },
          {
            q: '¿Está indexado el monto del alquiler anual?',
            a: 'Sí, el monto de la tarifa está indexado en función de los valores IPC desde el momento en que el parque se conecta.',
          },
          {
            q: '¿Qué sucede al final del contrato, con respecto a los paneles y la planta?',
            a: 'Sun Investment Group se encarga de todos los proyectos. Después del contrato, desmantelaremos la planta y devolveremos el terreno a su estado original, asumiendo todos los costos asociados.',
          },
          {
            q: '¿Cuáles son los riesgos de los sistemas fotovoltaicos?',
            a: 'Un sistema fotovoltaico construido con módulos de silicio cristalino (como lo hace Sun Investment Group) es seguro tanto para las personas como para el medio ambiente, y por ley se construye cumpliendo con estándares muy estrictos, que siempre se respetan meticulosamente. En su funcionamiento no produce ningún tipo de residuo/desecho (ni agua residual, ni plástico, ni ruido) y no es fuente de radiación electromagnética.',
          },
          {
            q: '¿Qué tipo de mantenimiento se realizará?',
            a: 'Un sistema fotovoltaico requiere relativamente poco mantenimiento, tanto eléctrico como mecánico, que en cualquier caso es realizado únicamente por personal certificado y especializado. Los paneles solares se lavan periódicamente, pero con agua desmineralizada y sin utilizar ningún detergente para proteger la tierra y los propios paneles solares. Según la temporada, también se corta el césped, que se suele dejar en el suelo para mantener la riqueza del sustrato y no agotar la zona de cara a su desmantelamiento al final de la obra.',
          },
          {
            q: '¿Qué pasa con los animales?',
            a: 'Para la protección del sistema fotovoltaico se utilizan vallas que permiten la migración normal de pequeños animales (erizos, serpientes, etc.). Los módulos también tienen un revestimiento antirreflectante, lo que evita que las aves se deslumbran. Por lo tanto, no hay amenaza para los animales, tanto salvajes como de granja.',
          },
          {
            q: '¿Qué pasa con las plantas?',
            a: 'No se utilizan fertilizantes, nutrientes o herbicidas para mantener la vegetación por debajo del nivel de los módulos fotovoltaicos y para prevenir posibles incendios en verano. Sólo se utiliza la siega manual, lo que permite el pastoreo de ovejas u otros animales aptos para ello (gansos, patos, etc.). Por lo tanto, no se provoca la contaminación o polución del suelo, sino que se intenta mejorar las realidades agrícolas locales.',
          },
        ],
      },
    ],
  },
}

export default Object
  .fromEntries(Object
    .entries(translations)
    .map(([locale, { faqSections }]) => {
      let counter = 1

      return [
        locale,
        {
          faqSections: faqSections.map(section => ({
            ...section,
            faq: section.faq.map(faq => ({
              ...faq,
              number: counter++,
            })),
          })),
        },
      ]
    }))
