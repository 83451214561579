<template>
  <div class="SectionEligibility">
    <div class="body">
      <h3>
        {{ $t('title') }}
      </h3>
      <div class="content">
        <div v-for="(item, index) in items" :key="index" class="item">
          <Icon :name="item.icon" />
          <div class="text">
            <div class="label">
              {{ item.label }}
            </div> {{ item.text }}
          </div>
        </div>
        <div class="item" :class="{ uncentered: locale === 'pl' }">
          <Icon name="plug" />
          <div class="text">
            <div class="label">
              {{ $ta("items")[$ta("items").length - 1].label }}
            </div> {{ $ta("items")[$ta("items").length - 1].text }}
          </div>
        </div>
        <p v-if="locale === 'pl'">
          {{ $t('additional') }}
        </p>
      </div>
      <Btn @click="scrollToForm">
        {{ $t('cta') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import { getters, mergeLabels } from './../../../utils'
import { scrollToHash } from '../../../../router/smoothScroll'

export default {
  computed: {
    icons() {
      if (this.locale === 'pl') {
        return [
          { icon: 'area' },
          { icon: 'desert' },
        ]
      } else {
        return [
          { icon: 'area' },
          { icon: 'location' },
          { icon: 'desert' },
          { icon: 'sun' },
        ]
      }
    },
    items() {
      return mergeLabels(this.icons, this.$ta('items'))
    },
    ...getters(),
  },
  methods: {
    scrollToForm() {
      scrollToHash('#form')
    },
  },
}
</script>

<style lang="scss">
.SectionEligibility {
  // background-color: $grey-lighter;
  margin: 2rem 0;

  .body {
    padding: 2rem 0;

    .content {
      padding: 1rem 0;

      .item {
        align-items: center;
        display: flex;
        // font-size: 1.5rem;
        line-height: 1.5;
        margin: 1rem 0;

        .Icon {
          font-size: 2rem;
        }

        .text {
          margin-left: 1rem;

          .label {
            display: inline-block;
            font-weight: $bold;
          }
        }

        &.uncentered {
          align-items: normal;
        }
      }

      p {
        // font-size: 1.5rem;
        margin-top: 2rem;
      }
    }

    .Btn {
      margin-top: 1rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "additional": "",
    "cta": "",
    "items": [
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      }
    ],
    "title": ""
  },
  "en": {
    "additional": "",
    "cta": "Leave your number and we will contact you",
    "items": [
      {
        "label": "Area: ",
        "text": "from 2.1 Ha"
      },
      {
        "label": "Regions: ",
        "text": "Sicily, Puglia, Sardinia, Molise, Lazio, Abruzzo, Marche, Emilia-Romagna, Piedmont, Veneto, Campania, Basilicata"
      },
      {
        "label": "Soil: ",
        "text": "agricolo, industriale or wasteland"
      },
      {
        "label": "The land",
        "text": "must not be located in a hilly area"
      },
      {
        "label": "Infrastructure: ",
        "text": "power line nearby and access to a public road"
      }
    ],
    "title": "Is your land suitable for a solar farm?"
  },
  "it": {
    "additional": "",
    "cta": "Lasciaci i tuoi recapiti e ti contatteremo noi",
    "items": [
      {
        "label": "Dimensione minima: ",
        "text": "ogni tipologia viene analizzata singolarmente. E' possibile che l'appezzamento sia presentato come lotto di più proprietari confinanti"
      },
      {
        "label": "Regioni: ",
        "text": "Sicilia, Puglia, Sardegna, Molise, Lazio, Abruzzo, Marche, Emilia-Romagna, Piemonte, Veneto, Campania, Basilicata. Siamo aperti a valutare terreni anche al di fuori di queste regioni."
      },
      {
        "label": "Destinazione d'uso: ",
        "text": "agricolo, industriale o cava/discarica esaurita e bonificata"
      },
      {
        "label": "Il terreno",
        "text": "non deve essere situato in una zona collinare"
      },
      {
        "label": "Pendenza: ",
        "text": "pianeggiante o inclinato verso Sud"
      }
    ],
    "title": "Il tuo terreno è adatto per un impianto fotovoltaico?"
  },
  "lt": {
    "additional": "",
    "cta": "",
    "items": [
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      }
    ],
    "title": ""
  },
  "pl": {
    "additional": "Koniecznie jest także, aby działka posiadała księgę wieczystą, jeśli jej nie posiada, pomagamy ją założyć. Pozostałe aspekty sprawdzane są przez odpowiedni dział w naszej firmie.",
    "cta": "Zostaw nam swój numer, oddzwonimy",
    "items": [
      {
        "label": "Powierzchnia terenu: ",
        "text": "min. 10 ha"
      },
      {
        "label": "Rodzaj gruntu: ",
        "text": "IV-VI klasa lub nieużytki"
      },
      {
        "label": "Plan zagospodarowania: ",
        "text": "brak lub plan pod fotowoltaikę (wyznaczone są obszary, na których rozmieszczone będą urządzenia wytwarzające energię z odnawialnych źródeł energii o mocy przekraczającej 100 kW) /produkcję"
      },
      {
        "label": "",
        "text": ""
      },
      {
        "label": "",
        "text": ""
      }
    ],
    "title": "Jakie warunki musi spełniać dany obszar, by możliwa była dzierżawa pod budowę farmy solarnej?"
  },
  "es": {
    "additional": "",
    "cta": "Déjanos tus datos de contacto y nos pondremos en contacto contigo",
    "items": [
      {
        "label": "Superficie mínima: ",
        "text": "cada terreno se analiza individualmente. Es posible que un proyecto se presente con varios propietarios vecinos"
      },
      {
        "label": "Regiones: ",
        "text": "estamos abiertos a evaluar terrenos en todo el territorio nacional"
      },
      {
        "label": "Uso previsto: ",
        "text": "agrícola / industrial / cantera / vertedero u otros"
      },
      {
        "label": "El terreno",
        "text": "no debe estar ubicado en una zona montañosa"
      },
      {
        "label": "Pendiente: ",
        "text": "plano o inclinado hacia el sur. Se aceptan inclinación Este-Oeste por debajo del 15%"
      }
    ],
    "title": "¿Tu terreno es apto para un sistema fotovoltaico?"
  }
}
</i18n>
