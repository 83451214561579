<template>
  <MultiSelect
    :disabled="disabled"
    :fieldLabel="label"
    :internalSearch="false"
    :loading="isLoading"
    :multiple="false"
    :options="options"
    :placeholder="$t('searchPlaceholder')"
    :required="required"
    :searchable="true"
    :value="value"
    @search-change="setFilter"
    @update:value="$emit('update:value', $event)" />
</template>

<script>
import MultiSelect from 'views/components/MultiSelect'
// import { actions } from 'views/utils'
// import { debounce } from 'views/utils/async'

export default {
  components: {
    MultiSelect,
  },
  props: {
    value: String,
    disabled: { type: Boolean, default: false },
    required: Boolean,
    label: String,
    optionsAll: Array,
  },
  data() {
    return {
      isLoading: false,
      filter: '',
    }
  },
  computed: {
    options() {
      return this.optionsAll.filter(option => option.toLowerCase().includes(this.filter.toLowerCase()))
    },
    // fetchProjectsDebounced() {
    //   return debounce(1000, this.FETCH_PROJECT)
    // },
    // options() {
    //   if (!this.filter) {
    //     return this.query('project', {
    //       $sort: { id: -1 },
    //       $limit: 10,
    //     })
    //   }

    //   return this.query('project', this.projectQuery)
    // },
    // projectQuery() {
    //   return {
    //     $or: [
    //       { name: { $ilike: this.filter } },
    //       { nameShort: { $ilike: this.filter } },
    //     ],
    //   }
    // },
    // ...getters('money'),
  },
  methods: {
    setFilter(filter) {
      this.filter = filter
    },
    // fetchProjects(projectQuery) {
    //   this.$wrap(
    //     () => this.fetchProjectsDebounced(projectQuery),
    //     'isLoading',
    //   )
    // },
    // ...actions('FETCH_PROJECT'),
  },
  // watch: {
  //   filter() {
  //     if (!this.filter || this.filter.length < 2) return

  //     return this.communes.filter(option => option.includes(this.filter))

  //     // return this.fetchProjects(this.projectQuery)
  //   },
  // },
}
</script>

<style lang="scss">
.MultiSelect {
  // margin-bottom: 4rem;
}
</style>

<i18n>
{
  "de": {
    "searchPlaceholder": "Typ..."
  },
  "en": {
    "searchPlaceholder": "Type and choose one"
  },
  "it": {
    "searchPlaceholder": "Scriva e scelga uno"
  },
  "lt": {
    "searchPlaceholder": ""
  },
  "pl": {
    "searchPlaceholder": ""
  }
}
</i18n>
