<template>
  <div class="MapFooterLandowners">
    <iframe
      allowfullscreen=""
      height="250"
      loading="lazy"
      :src="mapSrc"
      style="border: 0;"
      title="address-map"
      width="300" />
  </div>
</template>

<script>
import { getters } from '../utils'

export default {
  computed: {
    mapSrc() {
      return this.locale === 'es'
        ? 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.64692593247!2d-3.691587523422181!3d40.460950952945886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42291bc8e5a1c9%3A0x8d14e880aa7f60a3!2sP.%C2%BA%20de%20la%20Castellana%2C%20182%2C%2028046%20Madrid%2C%20Ispanija!5e0!3m2!1ses!2ses!4v1693537499384!5m2!1ses!2ses'
        : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2845.5322517076097!2d11.346185951351089!3d44.50425030489638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fd4a21de3d835%3A0xb110cba0ab67a533!2sViale%20Angelo%20Masini%2C%2012%2C%2040126%20Bologna%20BO%2C%20Italija!5e0!3m2!1slt!2slt!4v1624977889788!5m2!1sit!2slt'
    },
    ...getters(),
  },
}
</script>
