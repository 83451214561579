<template>
  <ValidationProvider
    #default="scope"
    :disabled="!rules && required !== false"
    :name="name"
    :rules="rules"
    slim
    tag="div">
    <slot v-bind="scope" />
    <transition name="slide-fade">
      <div v-show="scope.errors[0]" class="FieldValidation-errors">
        {{ scope.errors[0] }}
      </div>
    </transition>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    name: String,
    rules: [String, Object],
  },
}
</script>

<style lang="scss">
.FieldValidation-errors {
  color: $danger;
  line-height: $lh;
}
</style>
