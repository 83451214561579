<template>
  <div class="SectionValue">
    <div class="body">
      <h3>
        {{ $t('title') }}
      </h3>
      <p v-if="locale === 'it' || locale === 'en' || locale === 'es'" v-html="$t('intro')" />
      <div class="text">
        <ol>
          <li v-for="item in $ta('items')" :key="item" v-html="item" />
        </ol>
      </div>
      <p v-if="locale === 'it' || locale === 'en' || locale === 'es'" v-html="$t('outro')" />
      <Btn v-if="locale !== 'lt'" @click="scrollToForm">
        {{ $t('cta') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import { getters } from 'views/utils'
import { scrollToHash } from '../../../../router/smoothScroll'

export default {
  computed: getters(),
  methods: {
    scrollToForm() {
      scrollToHash('#form')
    },
  },
}
</script>

<style lang="scss">
.SectionValue {
  // background-color: $grey-lighter;
  margin: 2rem 0;

  .body {
    padding: 2rem 0;

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin: 2rem 0;
    }

    .text {
      margin: 1rem 2rem;

      li {
        font-size: inherit;
        margin: 1rem 0;
        padding-left: 1rem;
      }
    }

    .Btn {
      margin-top: 1rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "cta": "",
    "intro": "",
    "items": [
      "",
      "",
      "",
      "",
      ""
    ],
    "outro": "",
    "title": ""
  },
  "en": {
    "cta": "Get your land evaluated",
    "intro": "",
    "items": [
      "30 years or more of secure revenue",
      "Guaranteed finalization of the project",
      "Low-impact infrastructure: no noise or emissions, limited maintenance activity",
      "",
      ""
    ],
    "outro": "",
    "title": "Why cooperate with Sun Investment Group?"
  },
  "it": {
    "cta": "Richiedi una valutazione del tuo terreno",
    "intro": "Sun Investment Group è un <b>fondo di investimento</b> e risulterà il tuo <b>unico referente diretto</b> durante il progetto, senza la presenza di alcun intermediario. Questi i passaggi che portano al buon fine di ogni operazione:",
    "items": [
      "Raccolta dei <b>dati necessari</b> per identificare il terreno",
      "<b>Soprallugo</b> dai nostri tecnici",
      "Formulazione della nostra <b>offerta</b> di vendita o affitto",
      "Sottoscrizione del Contratto <b>preliminare</b> e avvio <b>dell’iter autorizzativo</b>",
      "<b>Richiesta</b> di connessione, firma del <b>contratto definitivo</b> e avvio lavori"
    ],
    "outro": "Sun Investment Group si <b>fa carico del 100% dei costi associati al progetto:</b> richiesta di autorizazzioni, realizazzione dell’impianto, manutenzione e smaltimento. Inoltre, fino all’inizio dei lavori, potrai continuare ad utilizzare il tuo appezzamento come prima per attività agricola, allevamento o altro.",
    "title": "Come funziona?"
  },
  "lt": {
    "cta": "",
    "intro": "",
    "items": [
      "Nuspėjamos, ilgalaikės pajamos",
      "Žemės panaudojimo diversifikavimas",
      "Galimybė diferencijuoti pajamų šaltinius",
      "Netarši ir žalos nedaranti infrastruktūra",
      "Proga prisidėti prie švarios energijos gamybos vietos bendruomenei"
    ],
    "outro": "",
    "title": "Kokia yra žemės sklypo nuomos nauda?"
  },
  "pl": {
    "cta": "Chcesz wiedzieć więcej? Skontaktuj się z nami!",
    "intro": "",
    "items": [
      "Stabilne źródło długoterminowych dochodów.",
      "Możliwość dywersyfikacji (zróżnicowania) źródła dochodów dla właścicieli gruntów.",
      "Gwarancja bezpieczeństwa – budując farmy fotowoltaiczne dbamy o spełnienie wszystkich wymogów prawnych i środowiskowych.",
      "Profesjonalne prowadzenie projektów – zarządzamy nimi zgodnie z wytycznymi Międzynarodowego Stowarzyszenia Zarządzania Projektami, zapewniamy bezpośredni kontakt z osobą prowadzącą dany projekt - Kierownikiem Projektu.",
      "Realny wpływ na zmniejszenie zanieczyszczeń, poprzez wytwarzanie energii z odnawialnych źródeł."
    ],
    "outro": "",
    "title": "Zyskaj, oddając swoją ziemię w dzierżawę Sun Investment Group"
  },
  "es": {
    "cta": "Solicita una tasación de tu terreno",
    "intro": "Sun Investment Group es un fondo de inversión y será su único contacto directo durante el proyecto, sin la presencia de ningún intermediario. Estos son los pasos que conducen a la conclusión exitosa de cada operación:",
    "items": [
      "Recogida de <b>datos necesarios</b> para la identificación del terreno",
      "Análisis de la viabilidad técnico-económica por nuestros técnicos",
      "Envío de nuestra oferta de venta o alquiler",
      "Envío del contrato preliminar",
      "Firma del contrato"
    ],
    "outro": "Sun Investment Group corre con el 100% de los costes asociados al proyecto: solicitud de permisos, construcción de la planta, mantenimiento y eliminación. Además, hasta el inicio de las obras, podrá seguir utilizando su parcela como hasta ahora para actividades agrícolas, ganaderas u otras.",
    "title": "¿Como funciona?"
  }
}
</i18n>
