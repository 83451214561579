<template>
  <div class="SectionBlogLandowners">
    <Row>
      <Column v-for="blog in blogs" :key="blog.id" :md="4" :xl="3">
        <CardBlogLandowners :blog="blog" />
      </Column>
    </Row>
  </div>
</template>

<script>
import CardBlogLandowners from 'views/components/CardBlogLandowners'
import { getters } from 'views/utils'

export default {
  components: { CardBlogLandowners },
  computed: {
    limit() {
      return this.isDesktop ? 8 : 4
    },
    blogs() {
      return this.query('blogLandowner', {
        locale: this.locale,
        $limit: this.limit,
        $sort: {
          created_at: -1,
        },
      })
    },
    ...getters('isDesktop'),
  },
}
</script>

<style lang="scss">
.SectionBlogLandowners {
  margin: 3rem 0;
}
</style>
