<template>
  <div class="SectionExplanation">
    <div class="body">
      <h3>
        {{ $t('title') }}
      </h3>
      <p v-for="(text, index) in $t('paragraphs')" :key="index" class="text" v-html="injectSnippets(text)" />
      <Btn @click="scrollToForm">
        {{ $t('cta') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import { getters } from 'views/utils'
import { scrollToHash } from '../../../../router/smoothScroll'

export default {
  computed: getters('injectSnippets'),
  methods: {
    scrollToForm() {
      scrollToHash('#form')
    },
  },
}
</script>

<style lang="scss">
.SectionExplanation {
  // background-color: $grey-lighter;
  margin: 2rem 0;

  .body {
    padding: 2rem 0;

    .text {
      margin: 1rem 0;
    }

    .Btn {
      margin-top: 1rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "cta": "",
    "paragraphs": [
      "",
      ""
    ],
    "title": ""
  },
  "en": {
    "cta": "Get offer",
    "paragraphs": [
      "Sun Investment Group has completed over {constructedMw} MW of solar power plants in Europe. These power plants are based on the land, rented from farmers, entrepreneurs and local government units. With the success rate of 98% PV plants completed, the company guarantees fruitful cooperation and secure income for the agreed period.",
      ""
    ],
    "title": "Land lease for guaranteed income"
  },
  "it": {
    "cta": "Richiedi la tua offerta",
    "paragraphs": [
      "Siamo un team di oltre 190 professionisti dedicati interamente al settore fotovoltaico che garantiscono una <b>cooperazione vantaggiosa, senza intoppi e di lunga durata:</b> i nostri progetti hanno un <b>tasso di successo del 98%</b> nel raggiungere l’autorizzazione per la costruzione e sono presenti nell'elenco dei <b>40 più grandi portafogli di impianti fotovoltaici in Europa</b> (Solarplaza, 2021). Abbiamo sviluppato oltre <b>164 MWp</b> di impianti fotovoltaici in Europa su terreni affittati da agricoltori, imprenditori, proprietari terrieri ed enti locali.",
      ""
    ],
    "title": "Affidaci il tuo terreno per una rendita certa"
  },
  "lt": {
    "cta": "Susisiekite su SIG ekspertais",
    "paragraphs": [
      "„Sun Investment Group“ (SIG) bendradarbiauja su žemių savininkais dėl didelio masto saulės elektrinių vystymo. Išnuomavus savo žemės sklypą, stabilias pajamas galima užsitikrinti bent 35 metams. SIG komanda pasirūpins visu procesu nuo dokumentų tvarkymo iki elektrinės įrengimo bei jos priežiūros. Kiekvienam projektui prisikariamas vadovaujantis asmuo,  pasiruošęs atsakyti į visus klausimus ir pasirūpinti bet kokių iškilusių problemų sprendimu.",
      "SIG jau įrengė daugiau nei {constructedMw} MW galios saulės elektrinių bei šiuo metu vysto {developingGw} GW galios projektus Lietuvoje, Lenkijoje ir Italijoje."
    ],
    "title": "Įdarbinkite savo sklypą ir džiaukitės stabiliu pajamų šaltiniu"
  },
  "pl": {
    "cta": "Porozmawiaj z ekspertami SIG",
    "paragraphs": [
      "Współpracujemy z właścicielami gruntów, którzy szukają dodatkowego, stabilnego źródła dochodów. Do tej pory z sukcesem zrealizowaliśmy już 109 projektów farm słonecznych na terenie całej Polski. Farmy budowane są na gruntach gorszej klasy (IV-VI) lub nieużytkach, dzierżawionych od właścicieli ziem, to dzięki ich zaufaniu możemy rozwijać kolejne projekty.",
      ""
    ],
    "title": "Dzierżawa gruntu, to źródło dodatkowego i stabilnego zysku na lata"
  },
  "es": {
    "cta": "Solicita tu oferta",
    "paragraphs": [
      "Somos un equipo de más de 190 profesionales dedicados íntegramente al sector fotovoltaico que garantiza una <b>cooperación rentable, fluida y duradera:</b> nuestros proyectos tienen una <b>tasa de éxito del 98 %</b> para alcanzar la autorización de construcción y figuran en la lista de los <b>40 más grandes Carteras de plantas fotovoltaicas en Europa</b> (Solarplaza, 2021). Hemos desarrollado más de <b>193 MWp</b> de plantas fotovoltaicas en Europa en terrenos alquilados a agricultores, empresarios, propietarios y autoridades locales."
    ],
    "title": "Confíenos su terreno por una cierta renta"
  }
}
</i18n>
