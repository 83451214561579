<template>
  <div class="NumberItem">
    <div class="value">
      {{ value }}
    </div>
    <div class="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    description: String,
  },
}
</script>

<style lang="scss">
.NumberItem {
  margin: 3rem 0;
  max-width: 15rem;
  text-align: center;

  .value {
    font-size: $h2;
    font-weight: $bold;
  }

  .description {
    font-size: 1rem;
  }
}
</style>
