var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FieldValidation',{staticClass:"MultiSelect-cont",attrs:{"required":"","rules":_vm.rulesDerived},scopedSlots:_vm._u([{key:"default",fn:function({ classes }){return [(_vm.fieldLabel)?_c('label',{attrs:{"for":_vm.inputId}},[_vm._v(" "+_vm._s(_vm.fieldLabel)+" "),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v(" * ")]):_vm._e()]):_vm._e(),_c('Multiselect',_vm._g(_vm._b({ref:"select",staticClass:"MultiSelect",class:classes,attrs:{"allowEmpty":false,"options":_vm.optionsFormatted,"value":_vm.valueFormatted},on:{"close":function($event){return _vm.$emit('close')},"remove":_vm.remove,"select":_vm.add},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'Multiselect',{
      customLabel: _vm.customLabel,
      deselectLabel: _vm.deselectLabel,
      loading: _vm.loading,
      multiple: _vm.multiple,
      selectLabel: _vm.selectLabel,
      selectedLabel: _vm.selectedLabel,
      trackBy: _vm.trackBy,
      ..._vm.$props,
      ..._vm.$attrs,
    },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }