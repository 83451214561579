<template>
  <div class="SectionFaqLandownersLanding">
    <div
      v-for="section in $ta('faqSections')"
      :key="section.title"
      class="Faq">
      <Wrap class="xl">
        <h3>{{ section.title }}</h3>
        <Qa
          v-for="({ q, a, number }, index) in section.faq.slice(0, limit)"
          :key="index"
          :answer="a"
          :number="number"
          :question="q" />
        <Btn
          v-if="limit < faqCount"
          @click="increaseLimit">
          {{ showMoreText }}
        </Btn>
      </Wrap>
    </div>
  </div>
</template>

<script>
import Qa from 'views/components/Qa'
import faqSections from './faqSections'

export default {
  components: {
    Qa,
  },
  i18n: { sharedMessages: faqSections },
  data() {
    return {
      limit: 4,
      increaseStep: 4,
    }
  },
  computed: {
    faqCount() {
      return this.$ta('faqSections')[0].faq.length
    },
    showMoreText() {
      return `${this.$t('more')} (${this.limit}/${this.faqCount})`
    },
  },
  methods: {
    increaseLimit() {
      this.limit = Math.min(this.limit + this.increaseStep, this.faqCount)
    },
  },
}
</script>

<style lang="scss">
.SectionFaqLandownersLanding {
  .Faq {
    background-color: $grey-lightest;
    // margin: 4rem 0;
    padding: 4rem 0;

    h3 {
      margin-bottom: 2rem;
    }

    .Accordion .content {
      max-width: unset;
    }

    .Btn {
      margin-top: 1rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "more": ""
  },
  "en": {
    "more": "Show more"
  },
  "it": {
    "more": "Mostra di più"
  },
  "lt": {
    "more": ""
  },
  "pl": {
    "more": "Pokaż więcej"
  },
  "es": {
    "more": "Mostrar más"
  }
}
</i18n>
