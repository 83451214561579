<template>
  <Card :id="portfolio.id" class="CardPortfolioLandowner">
    <!-- <h4 class="text">
      {{ portfolio.location }}
    </h4> -->
    <div class="text">
      <h4>       {{ portfolio.location }} </h4>
    </div>
    <div class="picture">
      <div class="details">
        <div>
          {{ $t('area') }}: {{ portfolio.landscape_area }} ha
        </div>
        <div>
          {{ $t('capacity') }}: {{ portfolio.capacity }} MW
        </div>
      </div>
      <Pic
        :alt="portfolio.title"
        class="image"
        loading="lazy"
        :src="portfolio.media.url" />
    </div>
    <!-- <Splitter :left="6" :right="6">
      <Pic
        :alt="portfolio.title"
        class="image"
        loading="lazy"
        slot="left"
        :src="portfolio.media.url" />

      <div slot="right" class="body">
        {{ portfolio.location }}
      </div>
    </Splitter> -->
  </Card>
</template>

<script>
// import Splitter from 'views/components/Splitter'
import { getters } from '../../../utils'

export default {
  // components: { Splitter },
  props: {
    portfolio: Object,
  },
  computed: {
    ...getters('getPortfolioItemImage'),
  },
}
</script>

<style lang="scss">
.CardPortfolioLandowner {
  .Column {
    padding: 0;
  }

  .text {
    height: 100%;
    max-width: unset;
    padding: 1rem;
    width: 100%;
  }

  .picture {
    position: relative;

    .details {
      background: $white;
      border-radius: 0 0 5px;
      padding: 0.5rem 1rem;
      position: absolute;
    }
  }

  .image > img {
    display: block;
    max-height: 10rem;
    object-fit: cover;
    width: 100%;
    @include lg {
      min-height: 12rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "area": "Bereich",
    "capacity": "Kapazität"
  },
  "en": {
    "area": "Area",
    "capacity": "Capacity"
  },
  "it": {
    "area": "Area",
    "capacity": "Capacità"
  },
  "lt": {
    "area": "Plotas",
    "capacity": "Galingumas"
  },
  "pl": {
    "area": "Obszar krajobrazowy",
    "capacity": "Moc"
  }
}
</i18n>
