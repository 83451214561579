<template>
  <div v-if="blog" id="BlogLandownerItem">
    <TopbarItalian />
    <Hero :src="blog.image.url">
      <h1>
        {{ blog.title }}
      </h1>
      <HeroScrollDown slot="outside" hash="#blog-content" />
    </Hero>
    <section id="blog-content">
      <Wrap class="md">
        <p class="date">
          <strong>{{ $d(new Date(blog.created_at), 'short') }}</strong>
        </p>
        <Markdown :hasStyles="true" :markdown="blog.content" />
      </Wrap>
    </section>
    <div class="ctaContainer">
      <Route class="Btn" :to="{ name: 'LandingLandowners', hash: '#form', params: { locale } }">
        {{ $t('cta') }}
      </Route>
    </div>
    <Wrap xl>
      <section class="blogsOther">
        <h3>Altri articoli</h3>
        <WrapScroll>
          <Column v-for="blogPost in blogsOther" :key="blogPost.id" class="item" :lg="5">
            <CardBlogLandowners :blog="blogPost" />
          </Column>
        </WrapScroll>
      </section>
    </Wrap>
    <FooterLandowners />
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import CardBlogLandowners from 'views/components/CardBlogLandowners'
import FooterLandowners from 'views/components/FooterLandowners'
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import TopbarItalian from 'views/components/TopbarItalian'
// import Meta from 'views/mixins/Meta'
import WrapScroll from 'views/components/WrapScroll'
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  // mixins: [
  //   Meta(
  //     ({ blog }) => blog?.title,
  //     ({ blog }) => blog?.content,
  //     ({ blog }) => blog?.image.url,
  //   ),
  // ],
  components: { CardBlogLandowners, FooterLandowners, Hero, HeroScrollDown, Markdown, WrapScroll, TopbarItalian },
  data() {
    return {
      // customQuery: {
      //   $or: [
      //     { locale: this.locale },
      //     { showOnAllLocales: true },
      //   ],
      // },
      limitBlogs: 10,
    }
  },
  computed: {
    blog() {
      return this.query('blogLandowner', { slug: this.$route.params.slug }, true)
    },
    blogs() {
      const queryParams = {
        // $or: [
        //   { locale: this.locale },
        //   { showOnAllLocales: true },
        // ],
        $sort: {
          // sorting by boolean do not work
          // featured: -1,
          created_at: -1,
        },
        $limit: this.limitBlogs,
      }

      return this.query('blogLandowner', queryParams)
    },
    blogsOther() {
      return this.blogs.filter(blog => blog.id !== this.blog.id)
    },
    ...getters(),
  },
  methods: actions('FETCH_BLOG_LANDOWNER', 'FETCH_BLOG'),
  async beforeMount() {
    await this.LOAD(this.FETCH_BLOG_LANDOWNER())
    await this.LOAD(this.FETCH_BLOG())
  },
}
</script>

<style lang="scss">
#BlogLandownerItem {
  .Hero {
    min-height: 60vh;

    h1 {
      font-size: $h3;
      margin-top: -10rem;
      @include md {
        font-size: $h2;
        margin-top: unset;
      }
    }

    img {
      filter: opacity(0.3);
      height: 100%;
      width: 100%;
    }
  }

  .ctaContainer {
    text-align: center;
  }

  p.date {
    margin-bottom: 2rem;
  }

  .blogsOther {
    h3 {
      margin-bottom: 2rem;
    }
  }
}

// #blog-content {
//   background: $white;

//   p {
//     margin-bottom: 2rem;
//     // text-indent: 1rem;
//   }

//   &.has-next {
//     margin-bottom: 10rem;
//   }

//   .BtnShare {
//     float: right;

//     > .BtnRound {
//       background: $grey-lighter;
//     }
//   }
// }
</style>

<i18n>
{
  "de": {
    "cta": "",
    "next": "",
    "otherArticles": ""
  },
  "en": {
    "cta": "Get offer",
    "next": "Read next",
    "otherArticles": "Other articles"
  },
  "it": {
    "cta": "Richiedi la tua valutazione",
    "next": "",
    "otherArticles": "Altri articoli"
  },
  "lt": {
    "cta": "",
    "next": "Kitas įrašas",
    "otherArticles": ""
  },
  "pl": {
    "cta": "",
    "next": "Czytaj następny",
    "otherArticles": ""
  }
}
</i18n>
